import React, {
  createContext, useContext, useCallback, useEffect, useMemo, useState,
} from 'react';
import Cookies from 'js-cookie';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';
import useApiQuery from '@/components/Hooks/useApiQuery';
import { LogOut } from 'lucide-react';

const ActingAsUserContext = createContext(null);

export function ActingAsUserProvider({ children }) {
  const [actingUser, setActingUser] = useState(null);

  const {
    data: {
      data: {
        user = null,
      },
    },
  } = useApiQuery(
    `/api/users/profiles/${actingUser}`,
    'GET',
    [AuthMiddleware],
    {
      enabled: !!actingUser,
      initialData: { data: { user: null } },
    },
  );

  const setActingAsUser = useCallback((userId) => {
    setActingUser(userId);
    Cookies.set('acting_as', userId, {
      SameSite: 'Lax',
    });
    window.location.reload();
  }, []);

  const isActingAsUser = useCallback(() => !!Cookies.get('acting_as'), []);

  const disableActingAsUser = useCallback(() => {
    setActingUser(null);
    Cookies.remove('acting_as');
    window.location.reload();
  }, []);

  useEffect(() => {
    setActingUser(isActingAsUser() ? Cookies.get('acting_as') : null);
  }, []);

  const values = useMemo(() => ({
    set: setActingAsUser,
    isActing: isActingAsUser,
    disable: disableActingAsUser,
  }), [setActingAsUser, isActingAsUser, disableActingAsUser]);

  return (
    <ActingAsUserContext.Provider value={values}>
      {children}
      {(!!actingUser && !!user) && (
        <div className="fixed left-1/2 top-0 z-[99999] flex -translate-x-1/2 flex-row items-center justify-center gap-4 rounded-b-lg bg-red-500 px-5 py-1 text-center font-semibold text-white">
          <span>
            Eingeloggt als:
            {' '}
            {user?.name}
          </span>
          <button
            type="button"
            onClick={disableActingAsUser}
            className="ml-2 text-white hover:text-white/80"
          >
            <LogOut className="size-5" />
          </button>
        </div>
      )}
    </ActingAsUserContext.Provider>
  );
}

export const useActingAsUser = () => useContext(ActingAsUserContext);
