import useBannerSize from '@/components/Hooks/useBannerSize';
import { cn } from '@/lib/utils';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import BlurHashImage from '@/components/ui/blur-hash-image.jsx';

function Banner({
  className = '',
  imageUrl = '',
  children = null,
  maxHeight = 413,
  minHeight = 213,
  style = {},
  hideImageOnMobile = false,
  ...props
}) {
  const [bannerRef, bannerHeight] = useBannerSize(imageUrl, maxHeight, minHeight);

  const internalStyle = useMemo(() => {
    const computedStyle = {
      height: `${bannerHeight}px`,
    };

    if (!props?.hash) {
      computedStyle.background = 'linear-gradient(to right, #FF585D 0%, #FFC100 100%)';
    }

    return {
      ...computedStyle,
      ...style,
    };
  }, [style, bannerHeight, props?.hash]);

  return (
    <BlurHashImage
      ref={bannerRef}
      className={cn(
        `flex max-h-[${maxHeight}px] min-h-[${minHeight}px] w-full rounded-3xl object-cover object-center`,
        imageUrl ? '' : 'bg-gradient-to-r from-[#FF585D] from-[0%] to-[#FFC100] to-[100%]',
        hideImageOnMobile ? 'max-[640px]:!bg-gradient-to-r from-[#FF585D] from-[0%] to-[#FFC100] to-[100%] [&>img]:invisible sm:[&>img]:visible' : '',
        className,
      )}
      style={internalStyle}
      src={imageUrl}
      {...props}
    >
      <div className="relative flex size-full flex-col justify-end self-end p-8">
        {children}
      </div>
    </BlurHashImage>
  );
}

export default Banner;

Banner.propTypes = {
  className: PropTypes.string,
  imageUrl: PropTypes.string,
  children: PropTypes.node,
  maxHeight: PropTypes.number,
  minHeight: PropTypes.number,
  hideImageOnMobile: PropTypes.bool,
  style: PropTypes.object,
};
