import { IdleTimerProvider } from 'react-idle-timer';
import { useState } from 'react';
import AFKModal from '@/components/Popups/AFKModal.jsx';
import { useAuth } from '@/contexts/AuthContext.jsx';

export default function AFKProvider({ children = null }) {
  const [showPopup, setShowPopup] = useState(false);
  const { logout } = useAuth();

  const onPrompt = () => {
    setShowPopup(true);
  };

  const onIdle = () => {
    setShowPopup(false);
    logout();
  };

  return (
    <IdleTimerProvider
      timeout={1000 * 60 * 30}
      onPrompt={onPrompt}
      promptBeforeIdle={1000 * 60}
      onIdle={onIdle}
      onActive={() => setShowPopup(false)}
      crossTab
      syncTimers={1000}
    >
      {children}
      <AFKModal showPopup={showPopup} />
    </IdleTimerProvider>
  );
}
