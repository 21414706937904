import i18next from 'i18next';

// German translations
import errorsDe from '@/../locales/de/errors.json';
import commonsDe from '@/../locales/de/commons.json';

// English translations
import errorsEn from '@/../locales/en/errors.json';
import commonsEn from '@/../locales/en/commons.json';

const loadLanguages = async (defaultLanguageCode) => {
  await i18next.init({
    lng: defaultLanguageCode,
    fallbackLng: 'de',
    resources: {
      de: {
        errors: errorsDe,
        commons: commonsDe,
      },
      en: {
        errors: errorsEn,
        commons: commonsEn,
      },
    },
  });
};

export default loadLanguages;
