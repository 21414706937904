import { useUser } from '@/contexts/UserContext.jsx';
import { useMemo } from 'react';

export default function usePermission(permissions) {
  const { hasPermission } = useUser();

  const result = useMemo(() => {
    if (Array.isArray(permissions)) {
      return permissions.map((permission) => hasPermission(permission));
    }
    return hasPermission(permissions);
  }, [permissions, hasPermission]);

  const total = useMemo(() => {
    if (Array.isArray(result)) {
      return result.every((permission) => permission);
    }
    return result;
  }, [result]);

  return [result, total];
}
