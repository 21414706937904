import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import AdminButton from '@/components/Navigation/AdminButton.jsx';
import { Link } from '@tanstack/react-router';
import { useUser } from '@/contexts/UserContext.jsx';
import UserAvatar from '@/components/Users/UserAvatar.jsx';
import usePermission from '@/components/Hooks/usePermission.js';
import {
  PlatformLayoutMyProfile as ProfileRoute,
} from '@/routePaths.gen.js';

// NOTE: We disable the rule because "?format=webp..." is valid
// eslint-disable-next-line import/no-unresolved
import NovartisLogoImage from '@/../assets/logos/novartis-logo-rgb.svg?format=webp&width=136&height=48';

export default function NavTopbar({ inCustomerView = true }) {
  const [adminPortalPermission] = usePermission('admin.portal');

  const { user } = useUser();

  return (
    <nav className="sticky left-0 top-0 z-10 flex h-16 w-full flex-row justify-center bg-white px-5">
      <NavTopbarMenu className="flex flex-row content-start">
        <NavTopbarItem className="hidden h-12 min-h-12 md:block">
          <Link to="/">
            <img
              src={NovartisLogoImage}
              alt="Novartis Logo"
              className="h-12 min-h-12"
            />
          </Link>
        </NavTopbarItem>
      </NavTopbarMenu>
      <NavTopbarMenu className="flex grow flex-row-reverse content-end">
        {!!user && (
        <React.Fragment>
          <NavTopbarItem>
            <Link to={ProfileRoute}>
              <UserAvatar user={user} className="aspect-square size-12" />
            </Link>
          </NavTopbarItem>
          {/* <NavTopbarItem>
            <NotificationButton hasUnreadNotifications />
          </NavTopbarItem> */}
          {adminPortalPermission && (
          <NavTopbarItem>
            <AdminButton inCustomerView={inCustomerView} />
          </NavTopbarItem>
          )}
        </React.Fragment>
        )}
      </NavTopbarMenu>
    </nav>
  );
}

NavTopbar.propTypes = {
  inCustomerView: PropTypes.bool,
};

function NavTopbarMenu({ children, className = '' }) {
  return (
    <ul className={cn('flex items-center gap-6', className)}>{children}</ul>
  );
}

NavTopbarMenu.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};

const NavTopbarItem = memo(({ children, className }) => (
  <li className={className}>{children}</li>
));

NavTopbarItem.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
};
