import { createFileRoute, notFound } from '@tanstack/react-router';
import { mutationFn } from '@/components/Hooks/useApiMutation.js';
import AuthMiddleware from '@/Middlewares/AuthMiddleware.js';

export const Route = createFileRoute(
  '/_admin-layout/admin/adboards_/$adboardId/preview',
)({
  loader: async ({ params: { adboardId }, context }) => {
    const id = adboardId.split('-').pop();
    const data = await mutationFn(
      'GET',
      [AuthMiddleware],
      { endpoint: `/api/adboards/${id}/preview` },
      context,
    );
    return data.data.adboard;
  },
  loaderDeps: () => [Date.now()], // This is a workaround to force the loader to run every time the route is navigated to
});
