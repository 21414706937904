import React from 'react';
import PropTypes from 'prop-types';
import { useUser } from '@/contexts/UserContext.jsx';
import Banner from '@/components/ui/banner.jsx';

function WelcomeBanner({ className = '', imageUrl, hash = null }) {
  const { user } = useUser();

  return (
    <Banner imageUrl={imageUrl} hash={hash} className={className} hideImageOnMobile>
      <div className="flex w-full flex-col justify-end">
        <p className="truncate text-lg text-white sm:text-2xl">
          Herzlich willkommen
        </p>
        <h3 className="inline-block truncate text-2xl font-bold leading-tight text-white max-xl:text-4xl sm:text-5xl">
          {user && user.name}
        </h3>
      </div>
    </Banner>
  );
}

export default WelcomeBanner;

WelcomeBanner.propTypes = {
  className: PropTypes.string,
  imageUrl: PropTypes.string.isRequired,
};
