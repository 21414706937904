import React, {
  createContext, useState, useContext, useCallback, useMemo, useRef, useEffect, useImperativeHandle,
} from 'react';
import PropTypes from 'prop-types';
import {
  deepDelta,
  deepMerge,
  getKeyValue, setKeyValue,
} from '@/lib/utils';
import EditorComponentType from '@/enums/EditorComponentType';
import { useBlocker } from '@tanstack/react-router';
import { useAdboard } from '@/components/Adboards/AdboardProvider.jsx';
import { useLoadingScreen } from '@/contexts/LoadingScreenContext.jsx';

const EditableContentContext = createContext(null);

export function getDefaultValue(key, editorConfig, editorDefault) {
  const parts = key.split('.');
  const parsedParts = [];
  let config = editorConfig;
  for (let i = 0; i < parts.length; i += 1) {
    parsedParts.push(parts[i]);
    config = config.find((c) => c.name === parts[i]);
    if (!config || !config.group) break;
    if (config.type === EditorComponentType.GROUP) i += 1;
    config = config.group;
  }

  let defaultVal = null;
  config = editorConfig;
  for (let i = 0; i < parsedParts.length; i += 1) {
    config = config.find((c) => c.name === parsedParts[i]);
    if (!config) break;
    if (i === parsedParts.length - 1) defaultVal = config.default;
    else config = config.group;
  }

  return defaultVal || editorDefault[key];
}

const EditableContentProvider = React.forwardRef(({
  enabled = false, children = null,
}, ref) => {
  const [componentElements, setComponentElements] = useState([]);
  const [activeElement, setActiveElement] = useState(null);
  const [componentProps, setComponentProps] = useState({});
  const [changedConfig, setChangedConfig] = useState({});

  const [isDirty, setDirty] = useState(false);
  const { setLoadingScreenVisible, setLoadingScreenText } = useLoadingScreen();

  useBlocker({
    blockerFn: () => window.confirm('Sie haben ungespeicherte Änderungen. Wollen Sie die Seite wirklich verlassen?'),
    condition: isDirty,
  });

  const { adboard, saveAdboard, publishAdboard } = useAdboard();

  useEffect(() => {
    setComponentProps(!activeElement ? {} : activeElement.current.componentProps);
  }, [activeElement]);

  useEffect(() => {
    if (!activeElement) return;
    activeElement.current.setComponentProps(componentProps);
  }, [activeElement, componentProps]);

  useEffect(() => {
    const savedConfig = adboard.settings['changed-config'] || {};
    setChangedConfig(Array.isArray(savedConfig) ? {} : savedConfig);
  }, [adboard]);

  const addComponentElement = useCallback((element) => {
    const props = adboard.settings[element.current.componentId] || {};
    element.current.setComponentProps(Array.isArray(props) ? {} : props);
    setComponentElements((oldElements) => [...oldElements, element]);
  }, [adboard]);

  const removeComponentElement = useCallback((element) => {
    setComponentElements((oldElements) => oldElements.filter((el) => el !== element));
  }, []);

  const internalSetConfig = useCallback((data) => {
    setChangedConfig((oldConfig) => deepMerge(oldConfig, data));
    setDirty(true);
  }, []);

  const getSettings = useCallback(() => {
    const newSettings = {};
    componentElements.forEach((element) => {
      newSettings[element.current.componentId] = element.current.componentProps;
    });
    newSettings['changed-config'] = changedConfig;
    return newSettings;
  }, [componentElements, changedConfig]);

  const saveSettings = useCallback(async () => {
    setLoadingScreenVisible(true);
    setLoadingScreenText('Änderungen werden gespeichert...');
    await saveAdboard({
      settings: getSettings(),
    });
    setDirty(false);
    setLoadingScreenVisible(false);
  }, [componentElements, saveAdboard, getSettings, setLoadingScreenVisible, setLoadingScreenText]);

  const publish = useCallback(async () => {
    setLoadingScreenVisible(true);
    setLoadingScreenText('Änderungen werden veröffentlicht...');
    await publishAdboard({
      settings: getSettings(),
      ...changedConfig,
    });
    setChangedConfig({});
    setDirty(false);
    setLoadingScreenVisible(false);
  }, [publishAdboard, changedConfig, getSettings, setLoadingScreenVisible, setLoadingScreenText]);

  const editorDefault = useMemo(
    () => (activeElement !== null ? activeElement.current.editorDefault : {}),
    [activeElement],
  );
  const editorConfig = useMemo(
    () => (activeElement !== null ? activeElement.current.editorConfig : null),
    [activeElement],
  );

  const getDefaultValueWrapper = useCallback(
    (key) => getDefaultValue(key, editorConfig, editorDefault),
    [editorConfig, editorDefault],
  );

  const getProp = useCallback(
    (key) => getKeyValue(componentProps, key, getDefaultValueWrapper(key)),
    [componentProps, getDefaultValueWrapper],
  );

  const setProp = useCallback((key, value) => {
    if (!enabled) return;
    setDirty(true);
    setComponentProps((oldProps) => setKeyValue(oldProps, key, value));
  }, [enabled, setComponentProps]);

  const values = useMemo(() => ({
    enabled,
    addComponentElement,
    removeComponentElement,
    activeElement: activeElement ? activeElement.current : null,
    setActiveElementRef: setActiveElement,
    componentProps,
    setComponentProps,
    editorConfig,
    getProp,
    setProp,
    isDirty,
    setChangedConfig: internalSetConfig,
    changedConfig,
    saveSettings,
    publish,
  }), [
    enabled,
    addComponentElement,
    removeComponentElement,
    activeElement,
    setActiveElement,
    componentProps,
    setComponentProps,
    editorConfig,
    getProp,
    setProp,
    isDirty,
    internalSetConfig,
    changedConfig,
    saveSettings,
    publish,
  ]);

  useImperativeHandle(ref, () => values, [values]);

  return (
    <EditableContentContext.Provider value={values}>
      <div>{children}</div>
    </EditableContentContext.Provider>
  );
});
EditableContentProvider.displayName = 'EditableContentProvider';

EditableContentProvider.propTypes = {
  enabled: PropTypes.bool,
  children: PropTypes.node,
};

export { EditableContentProvider };

export const useEditable = () => useContext(EditableContentContext);

export const useChangedConfig = (key, defaultValue = null, castGet = null, castSet = null) => {
  const { adboard } = useAdboard();
  const { changedConfig, setChangedConfig } = useEditable();

  const getBaseObject = useCallback(() => {
    const parts = key.split('.');
    if (parts.length <= 1) {
      return {
        baseKey: '',
        absKey: key,
        base: {},
      };
    }
    return {
      baseKey: parts[0],
      absKey: parts.slice(1).join('.'),
      base: getKeyValue(changedConfig, parts[0], getKeyValue(adboard, parts[0], {})),
    };
  }, [changedConfig, adboard, key]);

  const changeValue = useCallback((newValue) => {
    const v = castSet ? castSet(newValue) : newValue;

    const { baseKey, absKey, base } = getBaseObject();

    setChangedConfig(!baseKey.length ? setKeyValue({}, key, null) : setKeyValue({}, baseKey, null)); // Reset base
    setChangedConfig(!baseKey.length ? setKeyValue({}, key, v) : setKeyValue({}, baseKey, setKeyValue(base, absKey, v)));
  }, [key, setChangedConfig, castSet, getBaseObject]);

  const value = useMemo(
    () => {
      const v = getKeyValue(changedConfig, key, getKeyValue(adboard, key, defaultValue));
      if (castGet) return castGet(v);
      return v;
    },
    [changedConfig, adboard, castGet],
  );

  const delta = useMemo(() => {
    const changed = getKeyValue(changedConfig, key, null);
    const original = getKeyValue(adboard, key, null);
    return deepDelta(original, changed);
  }, [adboard, getBaseObject]);

  return [value, changeValue, delta];
};

export const useEditableContent = (componentId) => {
  const [element, setElement] = useState(null);
  const [editorDefault, setEditorDefault] = useState({});
  const [editorConfig, setEditorConfig] = useState(null);
  const [componentProps, setComponentProps] = useState({});
  const elementRef = useRef({
    componentId, componentProps, setComponentProps, editorDefault, editorConfig, element,
  });
  elementRef.current.componentId = componentId;
  elementRef.current.componentProps = componentProps;
  elementRef.current.setComponentProps = setComponentProps;
  elementRef.current.editorDefault = editorDefault;
  elementRef.current.editorConfig = editorConfig;
  elementRef.current.element = element;

  const {
    enabled: editorEnabled,
    addComponentElement,
    removeComponentElement,
    activeElement,
    setActiveElementRef,
  } = useEditable();

  const setActive = useCallback(() => {
    setActiveElementRef(elementRef);
  }, [setActiveElementRef, element]);

  const isActive = useMemo(() => activeElement === elementRef.current, [activeElement]);

  useEffect(() => {
    addComponentElement(elementRef);
    return () => {
      removeComponentElement(elementRef);
    };
  }, [addComponentElement, removeComponentElement, elementRef]);

  return {
    componentProps,
    setComponentProps,
    setElement,
    editorDefault,
    setEditorDefault,
    editorConfig,
    setEditorConfig,
    setActive,
    isActive,
    editorEnabled,
  };
};
