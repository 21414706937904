import React, { useEffect, useState } from 'react';
import Modal from '@/components/Popups/Modal.jsx';
import { Button } from '@/components/ui/button.jsx';
import { useIdleTimerContext } from 'react-idle-timer';
import { useInterval } from 'usehooks-ts';

export default function AFKModal({ showPopup }) {
  const [remaining, setRemaining] = useState(60);

  const { activate } = useIdleTimerContext();

  useInterval(() => {
    if (!showPopup || remaining === 0) return;
    setRemaining(remaining - 1);
  }, 1000);

  return (
    <Modal
      open={showPopup}
      onClose={activate}
      title="Sind Sie noch da?"
    >
      <div className="flex max-w-[600px] flex-col gap-8 rounded-2xl bg-white px-10 py-5 text-lg font-medium">
        <p className="font-medium">
          Scheinbar waren Sie länger als 30 Minuten inaktiv. Aus Sicherheitsgründen werden Sie in den nächsten
          {' '}
          <span className="font-semibold">{remaining}</span>
          {' '}
          Sekunden automatisch ausgeloggt.
        </p>
        <p>
          Falls Sie noch da sind, klicken Sie bitte auf den Button unten, um eingeloggt zu bleiben.
        </p>
        <Button
          onClick={activate}
          className="mb-4 h-10 self-center rounded-full bg-primary px-20 py-1 font-semibold text-primary-foreground hover:bg-primary/90"
        >
          Eingeloggt bleiben
        </Button>
      </div>
    </Modal>
  );
}
