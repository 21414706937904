import React, { useMemo } from 'react';
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from '@/components/ui/avatar.jsx';
import PropTypes from 'prop-types';
import useElementSize from '@/components/Hooks/useElementSize.js';

export default function UserAvatar({ className = '', user = null }) {
  const initials = useMemo(() => {
    if (!user) return '';
    const combinedName = `${user.first_name} ${user.last_name}`.trim();
    if (!combinedName) return '';
    const initialsArray = combinedName
      .replace(/[^a-zA-Z- ]/g, '')
      .trim()
      .toUpperCase()
      .replace(/\s+/g, ' ')
      .split(' ')
      .map((v) => v.charAt(0));
    return initialsArray
      .filter((v, i) => i === 0 || i === initialsArray.length - 1)
      .join('');
  }, [user]);

  const [size, ref] = useElementSize();

  return (
    <Avatar ref={ref} className={className}>
      {user && (user.avatar || user.hash) ? (
        <AvatarImage
          src={user.avatar}
          hash={user.hash}
          className="object-cover"
        />
      )
        : (
          <AvatarFallback>
            <div className="flex size-full items-center justify-center bg-gradient-to-r from-[#FF585D] to-[#FFC100]">
              <span
                className="text-center align-middle font-semibold text-white"
                style={{
                  fontSize: size.height ? `${Math.floor(size.height / 3)}px` : '0',
                }}
              >
                {initials}
              </span>
            </div>
          </AvatarFallback>
        )}
    </Avatar>
  );
}

UserAvatar.propTypes = {
  user: PropTypes.object,
  className: PropTypes.string,
};
