import { createFileRoute, notFound } from '@tanstack/react-router';
import { mutationFn } from '@/components/Hooks/useApiMutation.js';
import AuthMiddleware from '@/Middlewares/AuthMiddleware.js';

export const Route = createFileRoute('/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/')({
  loader: async ({ params: { adboard: adboardId, meeting: meetingId } }) => {
    const id = adboardId.split('-').pop();
    const data = await mutationFn('GET', [AuthMiddleware], { endpoint: `/api/adboards/${id}` });
    const { adboard } = data.data;
    const meetingData = await mutationFn('GET', [AuthMiddleware], { endpoint: `/api/meetings/${meetingId}` });
    const { meeting } = meetingData.data;
    return {
      adboard,
      meeting,
    };
  },
});
