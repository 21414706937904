/* eslint-disable */

// @ts-nocheck

// noinspection JSUnusedGlobalSymbols

// This file was automatically generated by TanStack Router.
// You should NOT make any changes in this file as it will be overwritten.
// Additionally, you should also exclude this file from your linter and/or formatter to prevent it from being checked or modified.

import { createFileRoute } from '@tanstack/react-router';

// Import Routes

import { Route as rootRoute } from './routes/__root';
import { Route as ReducedLayoutImport } from './routes/_reduced-layout';
import { Route as PlatformLayoutImport } from './routes/_platform-layout';
import { Route as LoginLayoutImport } from './routes/_login-layout';
import { Route as InfoPageLayoutImport } from './routes/_info-page-layout';
import { Route as AdminLayoutImport } from './routes/_admin-layout';
import { Route as R2faLayoutImport } from './routes/_2fa-layout';
import { Route as PlatformLayoutFeedbackImport } from './routes/_platform-layout/feedback_';
import { Route as LoginLayoutUserInviteImport } from './routes/_login-layout/user-invite';
import { Route as PlatformLayoutAdboardAdboardIndexImport } from './routes/_platform-layout/adboard/$adboard/index';
import { Route as AdminLayoutAdminUserHubIndexImport } from './routes/_admin-layout/admin/user-hub/index';
import { Route as AdminLayoutAdminOperationsHubIndexImport } from './routes/_admin-layout/admin/operations-hub/index';
import { Route as AdminLayoutAdminConferenceHubIndexImport } from './routes/_admin-layout/admin/conference-hub/index';
import { Route as AdminLayoutAdminAnalyticsHubIndexImport } from './routes/_admin-layout/admin/analytics-hub/index';
import { Route as RecordingMeetingMeetingTokenImport } from './routes/recording/meeting/$meeting/$token';
import { Route as PlatformLayoutAdboardAdboardOnDemandParticipationImport } from './routes/_platform-layout/adboard/$adboard/on-demand-participation_';
import { Route as PlatformLayoutAdboardAdboardForumImport } from './routes/_platform-layout/adboard/$adboard/forum_';
import { Route as ReducedLayoutAdboardAdboardMeetingIndexImport } from './routes/_reduced-layout/adboard/$adboard/meeting/index';
import { Route as AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexImport } from './routes/_admin-layout/admin/analytics-hub/feedback-insights/index';
import { Route as ReducedLayoutAdboardAdboardMeetingBreakoutImport } from './routes/_reduced-layout/adboard/$adboard/meeting/$breakout';
import { Route as AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveImport } from './routes/_admin-layout/admin/analytics-hub/adboard-insights/archive';
import { Route as AdminLayoutAdminAdboardsAdboardIdPreviewImport } from './routes/_admin-layout/admin/adboards_/$adboardId/preview';
import { Route as AdminLayoutAdminAdboardsAdboardIdEditImport } from './routes/_admin-layout/admin/adboards_/$adboardId/edit';
import { Route as AdminLayoutAdminAnalyticsHubUserInsightsUserIndexImport } from './routes/_admin-layout/admin/analytics-hub/user-insights_/$user/index';
import { Route as AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexImport } from './routes/_admin-layout/admin/analytics-hub/adboard-insights/$adboard/index';
import { Route as ReducedLayoutAdboardAdboardForumMeetingMeetingIndexImport } from './routes/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/index';

// Create Virtual Routes

const PlatformLayoutIndexLazyImport = createFileRoute('/_platform-layout/')();
const PlatformLayoutMyProfileLazyImport = createFileRoute(
  '/_platform-layout/my-profile',
)();
const LoginLayoutOtpLazyImport = createFileRoute('/_login-layout/otp')();
const LoginLayoutLoginLazyImport = createFileRoute('/_login-layout/login')();
const LoginLayoutForgotPasswordLazyImport = createFileRoute(
  '/_login-layout/forgot-password',
)();
const LoginLayoutChangePasswordLazyImport = createFileRoute(
  '/_login-layout/change-password',
)();
const R2faLayoutTwoFactorAuthenticatorLazyImport = createFileRoute(
  '/_2fa-layout/two-factor-authenticator',
)();
const PlatformLayoutHelpIndexLazyImport = createFileRoute(
  '/_platform-layout/help/',
)();
const AdminLayoutAdminIndexLazyImport = createFileRoute(
  '/_admin-layout/admin/',
)();
const PlatformLayoutHelpFaqLazyImport = createFileRoute(
  '/_platform-layout/help/faq',
)();
const InfoPageLayoutLegalGrundsaetzeLazyImport = createFileRoute(
  '/_info-page-layout/legal/grundsaetze',
)();
const InfoPageLayoutLegalDatenschutzLazyImport = createFileRoute(
  '/_info-page-layout/legal/datenschutz',
)();
const AdminLayoutAdminUserHubUsersLazyImport = createFileRoute(
  '/_admin-layout/admin/user-hub/users',
)();
const AdminLayoutAdminUserHubRolesLazyImport = createFileRoute(
  '/_admin-layout/admin/user-hub/roles',
)();
const AdminLayoutAdminFaqHubFaqLazyImport = createFileRoute(
  '/_admin-layout/admin/faq-hub/faq',
)();
const AdminLayoutAdminFaqHubCategoryLazyImport = createFileRoute(
  '/_admin-layout/admin/faq-hub/category',
)();
const AdminLayoutAdminAnalyticsHubUserInsightsLazyImport = createFileRoute(
  '/_admin-layout/admin/analytics-hub/user-insights',
)();
const AdminLayoutAdminOperationsHubPulseIndexLazyImport = createFileRoute(
  '/_admin-layout/admin/operations-hub/pulse_/',
)();
const AdminLayoutAdminOperationsHubAseReportingIndexLazyImport =
  createFileRoute('/_admin-layout/admin/operations-hub/ase-reporting_/')();
const AdminLayoutAdminConferenceHubMyAdboardsIndexLazyImport = createFileRoute(
  '/_admin-layout/admin/conference-hub/my-adboards_/',
)();
const AdminLayoutAdminConferenceHubContentManagerIndexLazyImport =
  createFileRoute('/_admin-layout/admin/conference-hub/content-manager_/')();
const AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyImport =
  createFileRoute('/_admin-layout/admin/analytics-hub/platform-insights_/')();
const AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyImport =
  createFileRoute('/_admin-layout/admin/analytics-hub/adboard-insights/')();
const AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyImport = createFileRoute(
  '/_admin-layout/admin/conference-hub/my-adboards_/drafts',
)();
const AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyImport =
  createFileRoute('/_admin-layout/admin/conference-hub/my-adboards_/archive')();
const AdminLayoutAdminConferenceHubContentManagerFolderLazyImport =
  createFileRoute(
    '/_admin-layout/admin/conference-hub/content-manager_/$folder',
  )();

// Create/Update Routes

const ReducedLayoutRoute = ReducedLayoutImport.update({
  id: '/_reduced-layout',
  getParentRoute: () => rootRoute,
} as any);

const PlatformLayoutRoute = PlatformLayoutImport.update({
  id: '/_platform-layout',
  getParentRoute: () => rootRoute,
} as any);

const LoginLayoutRoute = LoginLayoutImport.update({
  id: '/_login-layout',
  getParentRoute: () => rootRoute,
} as any);

const InfoPageLayoutRoute = InfoPageLayoutImport.update({
  id: '/_info-page-layout',
  getParentRoute: () => rootRoute,
} as any);

const AdminLayoutRoute = AdminLayoutImport.update({
  id: '/_admin-layout',
  getParentRoute: () => rootRoute,
} as any);

const R2faLayoutRoute = R2faLayoutImport.update({
  id: '/_2fa-layout',
  getParentRoute: () => rootRoute,
} as any);

const PlatformLayoutIndexLazyRoute = PlatformLayoutIndexLazyImport.update({
  id: '/',
  path: '/',
  getParentRoute: () => PlatformLayoutRoute,
} as any).lazy(() =>
  import('./routes/_platform-layout/index.lazy').then((d) => d.Route),
);

const PlatformLayoutMyProfileLazyRoute =
  PlatformLayoutMyProfileLazyImport.update({
    id: '/my-profile',
    path: '/my-profile',
    getParentRoute: () => PlatformLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_platform-layout/my-profile.lazy').then((d) => d.Route),
  );

const LoginLayoutOtpLazyRoute = LoginLayoutOtpLazyImport.update({
  id: '/otp',
  path: '/otp',
  getParentRoute: () => LoginLayoutRoute,
} as any).lazy(() =>
  import('./routes/_login-layout/otp.lazy').then((d) => d.Route),
);

const LoginLayoutLoginLazyRoute = LoginLayoutLoginLazyImport.update({
  id: '/login',
  path: '/login',
  getParentRoute: () => LoginLayoutRoute,
} as any).lazy(() =>
  import('./routes/_login-layout/login.lazy').then((d) => d.Route),
);

const LoginLayoutForgotPasswordLazyRoute =
  LoginLayoutForgotPasswordLazyImport.update({
    id: '/forgot-password',
    path: '/forgot-password',
    getParentRoute: () => LoginLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_login-layout/forgot-password.lazy').then((d) => d.Route),
  );

const LoginLayoutChangePasswordLazyRoute =
  LoginLayoutChangePasswordLazyImport.update({
    id: '/change-password',
    path: '/change-password',
    getParentRoute: () => LoginLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_login-layout/change-password.lazy').then((d) => d.Route),
  );

const R2faLayoutTwoFactorAuthenticatorLazyRoute =
  R2faLayoutTwoFactorAuthenticatorLazyImport.update({
    id: '/two-factor-authenticator',
    path: '/two-factor-authenticator',
    getParentRoute: () => R2faLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_2fa-layout/two-factor-authenticator.lazy').then(
      (d) => d.Route,
    ),
  );

const PlatformLayoutFeedbackRoute = PlatformLayoutFeedbackImport.update({
  id: '/feedback_',
  path: '/feedback',
  getParentRoute: () => PlatformLayoutRoute,
} as any).lazy(() =>
  import('./routes/_platform-layout/feedback_.lazy').then((d) => d.Route),
);

const LoginLayoutUserInviteRoute = LoginLayoutUserInviteImport.update({
  id: '/user-invite',
  path: '/user-invite',
  getParentRoute: () => LoginLayoutRoute,
} as any).lazy(() =>
  import('./routes/_login-layout/user-invite.lazy').then((d) => d.Route),
);

const PlatformLayoutHelpIndexLazyRoute =
  PlatformLayoutHelpIndexLazyImport.update({
    id: '/help/',
    path: '/help/',
    getParentRoute: () => PlatformLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_platform-layout/help/index.lazy').then((d) => d.Route),
  );

const AdminLayoutAdminIndexLazyRoute = AdminLayoutAdminIndexLazyImport.update({
  id: '/admin/',
  path: '/admin/',
  getParentRoute: () => AdminLayoutRoute,
} as any).lazy(() =>
  import('./routes/_admin-layout/admin/index.lazy').then((d) => d.Route),
);

const PlatformLayoutHelpFaqLazyRoute = PlatformLayoutHelpFaqLazyImport.update({
  id: '/help/faq',
  path: '/help/faq',
  getParentRoute: () => PlatformLayoutRoute,
} as any).lazy(() =>
  import('./routes/_platform-layout/help/faq.lazy').then((d) => d.Route),
);

const InfoPageLayoutLegalGrundsaetzeLazyRoute =
  InfoPageLayoutLegalGrundsaetzeLazyImport.update({
    id: '/legal/grundsaetze',
    path: '/legal/grundsaetze',
    getParentRoute: () => InfoPageLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_info-page-layout/legal/grundsaetze.lazy').then(
      (d) => d.Route,
    ),
  );

const InfoPageLayoutLegalDatenschutzLazyRoute =
  InfoPageLayoutLegalDatenschutzLazyImport.update({
    id: '/legal/datenschutz',
    path: '/legal/datenschutz',
    getParentRoute: () => InfoPageLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_info-page-layout/legal/datenschutz.lazy').then(
      (d) => d.Route,
    ),
  );

const PlatformLayoutAdboardAdboardIndexRoute =
  PlatformLayoutAdboardAdboardIndexImport.update({
    id: '/adboard/$adboard/',
    path: '/adboard/$adboard/',
    getParentRoute: () => PlatformLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_platform-layout/adboard/$adboard/index.lazy').then(
      (d) => d.Route,
    ),
  );

const AdminLayoutAdminUserHubIndexRoute =
  AdminLayoutAdminUserHubIndexImport.update({
    id: '/admin/user-hub/',
    path: '/admin/user-hub/',
    getParentRoute: () => AdminLayoutRoute,
  } as any);

const AdminLayoutAdminOperationsHubIndexRoute =
  AdminLayoutAdminOperationsHubIndexImport.update({
    id: '/admin/operations-hub/',
    path: '/admin/operations-hub/',
    getParentRoute: () => AdminLayoutRoute,
  } as any);

const AdminLayoutAdminConferenceHubIndexRoute =
  AdminLayoutAdminConferenceHubIndexImport.update({
    id: '/admin/conference-hub/',
    path: '/admin/conference-hub/',
    getParentRoute: () => AdminLayoutRoute,
  } as any);

const AdminLayoutAdminAnalyticsHubIndexRoute =
  AdminLayoutAdminAnalyticsHubIndexImport.update({
    id: '/admin/analytics-hub/',
    path: '/admin/analytics-hub/',
    getParentRoute: () => AdminLayoutRoute,
  } as any);

const AdminLayoutAdminUserHubUsersLazyRoute =
  AdminLayoutAdminUserHubUsersLazyImport.update({
    id: '/admin/user-hub/users',
    path: '/admin/user-hub/users',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_admin-layout/admin/user-hub/users.lazy').then(
      (d) => d.Route,
    ),
  );

const AdminLayoutAdminUserHubRolesLazyRoute =
  AdminLayoutAdminUserHubRolesLazyImport.update({
    id: '/admin/user-hub/roles',
    path: '/admin/user-hub/roles',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_admin-layout/admin/user-hub/roles.lazy').then(
      (d) => d.Route,
    ),
  );

const AdminLayoutAdminFaqHubFaqLazyRoute =
  AdminLayoutAdminFaqHubFaqLazyImport.update({
    id: '/admin/faq-hub/faq',
    path: '/admin/faq-hub/faq',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_admin-layout/admin/faq-hub/faq.lazy').then(
      (d) => d.Route,
    ),
  );

const AdminLayoutAdminFaqHubCategoryLazyRoute =
  AdminLayoutAdminFaqHubCategoryLazyImport.update({
    id: '/admin/faq-hub/category',
    path: '/admin/faq-hub/category',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_admin-layout/admin/faq-hub/category.lazy').then(
      (d) => d.Route,
    ),
  );

const AdminLayoutAdminAnalyticsHubUserInsightsLazyRoute =
  AdminLayoutAdminAnalyticsHubUserInsightsLazyImport.update({
    id: '/admin/analytics-hub/user-insights',
    path: '/admin/analytics-hub/user-insights',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/analytics-hub/user-insights.lazy'
    ).then((d) => d.Route),
  );

const RecordingMeetingMeetingTokenRoute =
  RecordingMeetingMeetingTokenImport.update({
    id: '/recording/meeting/$meeting/$token',
    path: '/recording/meeting/$meeting/$token',
    getParentRoute: () => rootRoute,
  } as any).lazy(() =>
    import('./routes/recording/meeting/$meeting/$token.lazy').then(
      (d) => d.Route,
    ),
  );

const PlatformLayoutAdboardAdboardOnDemandParticipationRoute =
  PlatformLayoutAdboardAdboardOnDemandParticipationImport.update({
    id: '/adboard/$adboard/on-demand-participation_',
    path: '/adboard/$adboard/on-demand-participation',
    getParentRoute: () => PlatformLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_platform-layout/adboard/$adboard/on-demand-participation_.lazy'
    ).then((d) => d.Route),
  );

const PlatformLayoutAdboardAdboardForumRoute =
  PlatformLayoutAdboardAdboardForumImport.update({
    id: '/adboard/$adboard/forum_',
    path: '/adboard/$adboard/forum',
    getParentRoute: () => PlatformLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_platform-layout/adboard/$adboard/forum_.lazy').then(
      (d) => d.Route,
    ),
  );

const AdminLayoutAdminOperationsHubPulseIndexLazyRoute =
  AdminLayoutAdminOperationsHubPulseIndexLazyImport.update({
    id: '/admin/operations-hub/pulse_/',
    path: '/admin/operations-hub/pulse/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/operations-hub/pulse_/index.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminOperationsHubAseReportingIndexLazyRoute =
  AdminLayoutAdminOperationsHubAseReportingIndexLazyImport.update({
    id: '/admin/operations-hub/ase-reporting_/',
    path: '/admin/operations-hub/ase-reporting/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/operations-hub/ase-reporting_/index.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminConferenceHubMyAdboardsIndexLazyRoute =
  AdminLayoutAdminConferenceHubMyAdboardsIndexLazyImport.update({
    id: '/admin/conference-hub/my-adboards_/',
    path: '/admin/conference-hub/my-adboards/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/conference-hub/my-adboards_/index.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminConferenceHubContentManagerIndexLazyRoute =
  AdminLayoutAdminConferenceHubContentManagerIndexLazyImport.update({
    id: '/admin/conference-hub/content-manager_/',
    path: '/admin/conference-hub/content-manager/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/conference-hub/content-manager_/index.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyRoute =
  AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyImport.update({
    id: '/admin/analytics-hub/platform-insights_/',
    path: '/admin/analytics-hub/platform-insights/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/analytics-hub/platform-insights_/index.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyRoute =
  AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyImport.update({
    id: '/admin/analytics-hub/adboard-insights/',
    path: '/admin/analytics-hub/adboard-insights/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/analytics-hub/adboard-insights/index.lazy'
    ).then((d) => d.Route),
  );

const ReducedLayoutAdboardAdboardMeetingIndexRoute =
  ReducedLayoutAdboardAdboardMeetingIndexImport.update({
    id: '/adboard/$adboard/meeting/',
    path: '/adboard/$adboard/meeting/',
    getParentRoute: () => ReducedLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_reduced-layout/adboard/$adboard/meeting/index.lazy').then(
      (d) => d.Route,
    ),
  );

const AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexRoute =
  AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexImport.update({
    id: '/admin/analytics-hub/feedback-insights/',
    path: '/admin/analytics-hub/feedback-insights/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/analytics-hub/feedback-insights/index.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyRoute =
  AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyImport.update({
    id: '/admin/conference-hub/my-adboards_/drafts',
    path: '/admin/conference-hub/my-adboards/drafts',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/conference-hub/my-adboards_/drafts.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyRoute =
  AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyImport.update({
    id: '/admin/conference-hub/my-adboards_/archive',
    path: '/admin/conference-hub/my-adboards/archive',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/conference-hub/my-adboards_/archive.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminConferenceHubContentManagerFolderLazyRoute =
  AdminLayoutAdminConferenceHubContentManagerFolderLazyImport.update({
    id: '/admin/conference-hub/content-manager_/$folder',
    path: '/admin/conference-hub/content-manager/$folder',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/conference-hub/content-manager_/$folder.lazy'
    ).then((d) => d.Route),
  );

const ReducedLayoutAdboardAdboardMeetingBreakoutRoute =
  ReducedLayoutAdboardAdboardMeetingBreakoutImport.update({
    id: '/adboard/$adboard/meeting/$breakout',
    path: '/adboard/$adboard/meeting/$breakout',
    getParentRoute: () => ReducedLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_reduced-layout/adboard/$adboard/meeting/$breakout.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveRoute =
  AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveImport.update({
    id: '/admin/analytics-hub/adboard-insights/archive',
    path: '/admin/analytics-hub/adboard-insights/archive',
    getParentRoute: () => AdminLayoutRoute,
  } as any);

const AdminLayoutAdminAdboardsAdboardIdPreviewRoute =
  AdminLayoutAdminAdboardsAdboardIdPreviewImport.update({
    id: '/admin/adboards_/$adboardId/preview',
    path: '/admin/adboards/$adboardId/preview',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/adboards_/$adboardId/preview.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminAdboardsAdboardIdEditRoute =
  AdminLayoutAdminAdboardsAdboardIdEditImport.update({
    id: '/admin/adboards_/$adboardId/edit',
    path: '/admin/adboards/$adboardId/edit',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import('./routes/_admin-layout/admin/adboards_/$adboardId/edit.lazy').then(
      (d) => d.Route,
    ),
  );

const AdminLayoutAdminAnalyticsHubUserInsightsUserIndexRoute =
  AdminLayoutAdminAnalyticsHubUserInsightsUserIndexImport.update({
    id: '/admin/analytics-hub/user-insights_/$user/',
    path: '/admin/analytics-hub/user-insights/$user/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/analytics-hub/user-insights_/$user/index.lazy'
    ).then((d) => d.Route),
  );

const AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexRoute =
  AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexImport.update({
    id: '/admin/analytics-hub/adboard-insights/$adboard/',
    path: '/admin/analytics-hub/adboard-insights/$adboard/',
    getParentRoute: () => AdminLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_admin-layout/admin/analytics-hub/adboard-insights/$adboard/index.lazy'
    ).then((d) => d.Route),
  );

const ReducedLayoutAdboardAdboardForumMeetingMeetingIndexRoute =
  ReducedLayoutAdboardAdboardForumMeetingMeetingIndexImport.update({
    id: '/adboard/$adboard/forum/meeting/$meeting/',
    path: '/adboard/$adboard/forum/meeting/$meeting/',
    getParentRoute: () => ReducedLayoutRoute,
  } as any).lazy(() =>
    import(
      './routes/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/index.lazy'
    ).then((d) => d.Route),
  );

// Populate the FileRoutesByPath interface

declare module '@tanstack/react-router' {
  interface FileRoutesByPath {
    '/_2fa-layout': {
      id: '/_2fa-layout';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof R2faLayoutImport;
      parentRoute: typeof rootRoute;
    };
    '/_admin-layout': {
      id: '/_admin-layout';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof AdminLayoutImport;
      parentRoute: typeof rootRoute;
    };
    '/_info-page-layout': {
      id: '/_info-page-layout';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof InfoPageLayoutImport;
      parentRoute: typeof rootRoute;
    };
    '/_login-layout': {
      id: '/_login-layout';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof LoginLayoutImport;
      parentRoute: typeof rootRoute;
    };
    '/_platform-layout': {
      id: '/_platform-layout';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof PlatformLayoutImport;
      parentRoute: typeof rootRoute;
    };
    '/_reduced-layout': {
      id: '/_reduced-layout';
      path: '';
      fullPath: '';
      preLoaderRoute: typeof ReducedLayoutImport;
      parentRoute: typeof rootRoute;
    };
    '/_login-layout/user-invite': {
      id: '/_login-layout/user-invite';
      path: '/user-invite';
      fullPath: '/user-invite';
      preLoaderRoute: typeof LoginLayoutUserInviteImport;
      parentRoute: typeof LoginLayoutImport;
    };
    '/_platform-layout/feedback_': {
      id: '/_platform-layout/feedback_';
      path: '/feedback';
      fullPath: '/feedback';
      preLoaderRoute: typeof PlatformLayoutFeedbackImport;
      parentRoute: typeof PlatformLayoutImport;
    };
    '/_2fa-layout/two-factor-authenticator': {
      id: '/_2fa-layout/two-factor-authenticator';
      path: '/two-factor-authenticator';
      fullPath: '/two-factor-authenticator';
      preLoaderRoute: typeof R2faLayoutTwoFactorAuthenticatorLazyImport;
      parentRoute: typeof R2faLayoutImport;
    };
    '/_login-layout/change-password': {
      id: '/_login-layout/change-password';
      path: '/change-password';
      fullPath: '/change-password';
      preLoaderRoute: typeof LoginLayoutChangePasswordLazyImport;
      parentRoute: typeof LoginLayoutImport;
    };
    '/_login-layout/forgot-password': {
      id: '/_login-layout/forgot-password';
      path: '/forgot-password';
      fullPath: '/forgot-password';
      preLoaderRoute: typeof LoginLayoutForgotPasswordLazyImport;
      parentRoute: typeof LoginLayoutImport;
    };
    '/_login-layout/login': {
      id: '/_login-layout/login';
      path: '/login';
      fullPath: '/login';
      preLoaderRoute: typeof LoginLayoutLoginLazyImport;
      parentRoute: typeof LoginLayoutImport;
    };
    '/_login-layout/otp': {
      id: '/_login-layout/otp';
      path: '/otp';
      fullPath: '/otp';
      preLoaderRoute: typeof LoginLayoutOtpLazyImport;
      parentRoute: typeof LoginLayoutImport;
    };
    '/_platform-layout/my-profile': {
      id: '/_platform-layout/my-profile';
      path: '/my-profile';
      fullPath: '/my-profile';
      preLoaderRoute: typeof PlatformLayoutMyProfileLazyImport;
      parentRoute: typeof PlatformLayoutImport;
    };
    '/_platform-layout/': {
      id: '/_platform-layout/';
      path: '/';
      fullPath: '/';
      preLoaderRoute: typeof PlatformLayoutIndexLazyImport;
      parentRoute: typeof PlatformLayoutImport;
    };
    '/_info-page-layout/legal/datenschutz': {
      id: '/_info-page-layout/legal/datenschutz';
      path: '/legal/datenschutz';
      fullPath: '/legal/datenschutz';
      preLoaderRoute: typeof InfoPageLayoutLegalDatenschutzLazyImport;
      parentRoute: typeof InfoPageLayoutImport;
    };
    '/_info-page-layout/legal/grundsaetze': {
      id: '/_info-page-layout/legal/grundsaetze';
      path: '/legal/grundsaetze';
      fullPath: '/legal/grundsaetze';
      preLoaderRoute: typeof InfoPageLayoutLegalGrundsaetzeLazyImport;
      parentRoute: typeof InfoPageLayoutImport;
    };
    '/_platform-layout/help/faq': {
      id: '/_platform-layout/help/faq';
      path: '/help/faq';
      fullPath: '/help/faq';
      preLoaderRoute: typeof PlatformLayoutHelpFaqLazyImport;
      parentRoute: typeof PlatformLayoutImport;
    };
    '/_admin-layout/admin/': {
      id: '/_admin-layout/admin/';
      path: '/admin';
      fullPath: '/admin';
      preLoaderRoute: typeof AdminLayoutAdminIndexLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_platform-layout/help/': {
      id: '/_platform-layout/help/';
      path: '/help';
      fullPath: '/help';
      preLoaderRoute: typeof PlatformLayoutHelpIndexLazyImport;
      parentRoute: typeof PlatformLayoutImport;
    };
    '/_platform-layout/adboard/$adboard/forum_': {
      id: '/_platform-layout/adboard/$adboard/forum_';
      path: '/adboard/$adboard/forum';
      fullPath: '/adboard/$adboard/forum';
      preLoaderRoute: typeof PlatformLayoutAdboardAdboardForumImport;
      parentRoute: typeof PlatformLayoutImport;
    };
    '/_platform-layout/adboard/$adboard/on-demand-participation_': {
      id: '/_platform-layout/adboard/$adboard/on-demand-participation_';
      path: '/adboard/$adboard/on-demand-participation';
      fullPath: '/adboard/$adboard/on-demand-participation';
      preLoaderRoute: typeof PlatformLayoutAdboardAdboardOnDemandParticipationImport;
      parentRoute: typeof PlatformLayoutImport;
    };
    '/recording/meeting/$meeting/$token': {
      id: '/recording/meeting/$meeting/$token';
      path: '/recording/meeting/$meeting/$token';
      fullPath: '/recording/meeting/$meeting/$token';
      preLoaderRoute: typeof RecordingMeetingMeetingTokenImport;
      parentRoute: typeof rootRoute;
    };
    '/_admin-layout/admin/analytics-hub/user-insights': {
      id: '/_admin-layout/admin/analytics-hub/user-insights';
      path: '/admin/analytics-hub/user-insights';
      fullPath: '/admin/analytics-hub/user-insights';
      preLoaderRoute: typeof AdminLayoutAdminAnalyticsHubUserInsightsLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/faq-hub/category': {
      id: '/_admin-layout/admin/faq-hub/category';
      path: '/admin/faq-hub/category';
      fullPath: '/admin/faq-hub/category';
      preLoaderRoute: typeof AdminLayoutAdminFaqHubCategoryLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/faq-hub/faq': {
      id: '/_admin-layout/admin/faq-hub/faq';
      path: '/admin/faq-hub/faq';
      fullPath: '/admin/faq-hub/faq';
      preLoaderRoute: typeof AdminLayoutAdminFaqHubFaqLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/user-hub/roles': {
      id: '/_admin-layout/admin/user-hub/roles';
      path: '/admin/user-hub/roles';
      fullPath: '/admin/user-hub/roles';
      preLoaderRoute: typeof AdminLayoutAdminUserHubRolesLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/user-hub/users': {
      id: '/_admin-layout/admin/user-hub/users';
      path: '/admin/user-hub/users';
      fullPath: '/admin/user-hub/users';
      preLoaderRoute: typeof AdminLayoutAdminUserHubUsersLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/analytics-hub/': {
      id: '/_admin-layout/admin/analytics-hub/';
      path: '/admin/analytics-hub';
      fullPath: '/admin/analytics-hub';
      preLoaderRoute: typeof AdminLayoutAdminAnalyticsHubIndexImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/conference-hub/': {
      id: '/_admin-layout/admin/conference-hub/';
      path: '/admin/conference-hub';
      fullPath: '/admin/conference-hub';
      preLoaderRoute: typeof AdminLayoutAdminConferenceHubIndexImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/operations-hub/': {
      id: '/_admin-layout/admin/operations-hub/';
      path: '/admin/operations-hub';
      fullPath: '/admin/operations-hub';
      preLoaderRoute: typeof AdminLayoutAdminOperationsHubIndexImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/user-hub/': {
      id: '/_admin-layout/admin/user-hub/';
      path: '/admin/user-hub';
      fullPath: '/admin/user-hub';
      preLoaderRoute: typeof AdminLayoutAdminUserHubIndexImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_platform-layout/adboard/$adboard/': {
      id: '/_platform-layout/adboard/$adboard/';
      path: '/adboard/$adboard';
      fullPath: '/adboard/$adboard';
      preLoaderRoute: typeof PlatformLayoutAdboardAdboardIndexImport;
      parentRoute: typeof PlatformLayoutImport;
    };
    '/_admin-layout/admin/adboards_/$adboardId/edit': {
      id: '/_admin-layout/admin/adboards_/$adboardId/edit';
      path: '/admin/adboards/$adboardId/edit';
      fullPath: '/admin/adboards/$adboardId/edit';
      preLoaderRoute: typeof AdminLayoutAdminAdboardsAdboardIdEditImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/adboards_/$adboardId/preview': {
      id: '/_admin-layout/admin/adboards_/$adboardId/preview';
      path: '/admin/adboards/$adboardId/preview';
      fullPath: '/admin/adboards/$adboardId/preview';
      preLoaderRoute: typeof AdminLayoutAdminAdboardsAdboardIdPreviewImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/analytics-hub/adboard-insights/archive': {
      id: '/_admin-layout/admin/analytics-hub/adboard-insights/archive';
      path: '/admin/analytics-hub/adboard-insights/archive';
      fullPath: '/admin/analytics-hub/adboard-insights/archive';
      preLoaderRoute: typeof AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_reduced-layout/adboard/$adboard/meeting/$breakout': {
      id: '/_reduced-layout/adboard/$adboard/meeting/$breakout';
      path: '/adboard/$adboard/meeting/$breakout';
      fullPath: '/adboard/$adboard/meeting/$breakout';
      preLoaderRoute: typeof ReducedLayoutAdboardAdboardMeetingBreakoutImport;
      parentRoute: typeof ReducedLayoutImport;
    };
    '/_admin-layout/admin/conference-hub/content-manager_/$folder': {
      id: '/_admin-layout/admin/conference-hub/content-manager_/$folder';
      path: '/admin/conference-hub/content-manager/$folder';
      fullPath: '/admin/conference-hub/content-manager/$folder';
      preLoaderRoute: typeof AdminLayoutAdminConferenceHubContentManagerFolderLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/conference-hub/my-adboards_/archive': {
      id: '/_admin-layout/admin/conference-hub/my-adboards_/archive';
      path: '/admin/conference-hub/my-adboards/archive';
      fullPath: '/admin/conference-hub/my-adboards/archive';
      preLoaderRoute: typeof AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/conference-hub/my-adboards_/drafts': {
      id: '/_admin-layout/admin/conference-hub/my-adboards_/drafts';
      path: '/admin/conference-hub/my-adboards/drafts';
      fullPath: '/admin/conference-hub/my-adboards/drafts';
      preLoaderRoute: typeof AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/analytics-hub/feedback-insights/': {
      id: '/_admin-layout/admin/analytics-hub/feedback-insights/';
      path: '/admin/analytics-hub/feedback-insights';
      fullPath: '/admin/analytics-hub/feedback-insights';
      preLoaderRoute: typeof AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_reduced-layout/adboard/$adboard/meeting/': {
      id: '/_reduced-layout/adboard/$adboard/meeting/';
      path: '/adboard/$adboard/meeting';
      fullPath: '/adboard/$adboard/meeting';
      preLoaderRoute: typeof ReducedLayoutAdboardAdboardMeetingIndexImport;
      parentRoute: typeof ReducedLayoutImport;
    };
    '/_admin-layout/admin/analytics-hub/adboard-insights/': {
      id: '/_admin-layout/admin/analytics-hub/adboard-insights/';
      path: '/admin/analytics-hub/adboard-insights';
      fullPath: '/admin/analytics-hub/adboard-insights';
      preLoaderRoute: typeof AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/analytics-hub/platform-insights_/': {
      id: '/_admin-layout/admin/analytics-hub/platform-insights_/';
      path: '/admin/analytics-hub/platform-insights';
      fullPath: '/admin/analytics-hub/platform-insights';
      preLoaderRoute: typeof AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/conference-hub/content-manager_/': {
      id: '/_admin-layout/admin/conference-hub/content-manager_/';
      path: '/admin/conference-hub/content-manager';
      fullPath: '/admin/conference-hub/content-manager';
      preLoaderRoute: typeof AdminLayoutAdminConferenceHubContentManagerIndexLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/conference-hub/my-adboards_/': {
      id: '/_admin-layout/admin/conference-hub/my-adboards_/';
      path: '/admin/conference-hub/my-adboards';
      fullPath: '/admin/conference-hub/my-adboards';
      preLoaderRoute: typeof AdminLayoutAdminConferenceHubMyAdboardsIndexLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/operations-hub/ase-reporting_/': {
      id: '/_admin-layout/admin/operations-hub/ase-reporting_/';
      path: '/admin/operations-hub/ase-reporting';
      fullPath: '/admin/operations-hub/ase-reporting';
      preLoaderRoute: typeof AdminLayoutAdminOperationsHubAseReportingIndexLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/operations-hub/pulse_/': {
      id: '/_admin-layout/admin/operations-hub/pulse_/';
      path: '/admin/operations-hub/pulse';
      fullPath: '/admin/operations-hub/pulse';
      preLoaderRoute: typeof AdminLayoutAdminOperationsHubPulseIndexLazyImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/analytics-hub/adboard-insights/$adboard/': {
      id: '/_admin-layout/admin/analytics-hub/adboard-insights/$adboard/';
      path: '/admin/analytics-hub/adboard-insights/$adboard';
      fullPath: '/admin/analytics-hub/adboard-insights/$adboard';
      preLoaderRoute: typeof AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_admin-layout/admin/analytics-hub/user-insights_/$user/': {
      id: '/_admin-layout/admin/analytics-hub/user-insights_/$user/';
      path: '/admin/analytics-hub/user-insights/$user';
      fullPath: '/admin/analytics-hub/user-insights/$user';
      preLoaderRoute: typeof AdminLayoutAdminAnalyticsHubUserInsightsUserIndexImport;
      parentRoute: typeof AdminLayoutImport;
    };
    '/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/': {
      id: '/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/';
      path: '/adboard/$adboard/forum/meeting/$meeting';
      fullPath: '/adboard/$adboard/forum/meeting/$meeting';
      preLoaderRoute: typeof ReducedLayoutAdboardAdboardForumMeetingMeetingIndexImport;
      parentRoute: typeof ReducedLayoutImport;
    };
  }
}

// Create and export the route tree

interface R2faLayoutRouteChildren {
  R2faLayoutTwoFactorAuthenticatorLazyRoute: typeof R2faLayoutTwoFactorAuthenticatorLazyRoute;
}

const R2faLayoutRouteChildren: R2faLayoutRouteChildren = {
  R2faLayoutTwoFactorAuthenticatorLazyRoute:
    R2faLayoutTwoFactorAuthenticatorLazyRoute,
};

const R2faLayoutRouteWithChildren = R2faLayoutRoute._addFileChildren(
  R2faLayoutRouteChildren,
);

interface AdminLayoutRouteChildren {
  AdminLayoutAdminIndexLazyRoute: typeof AdminLayoutAdminIndexLazyRoute;
  AdminLayoutAdminAnalyticsHubUserInsightsLazyRoute: typeof AdminLayoutAdminAnalyticsHubUserInsightsLazyRoute;
  AdminLayoutAdminFaqHubCategoryLazyRoute: typeof AdminLayoutAdminFaqHubCategoryLazyRoute;
  AdminLayoutAdminFaqHubFaqLazyRoute: typeof AdminLayoutAdminFaqHubFaqLazyRoute;
  AdminLayoutAdminUserHubRolesLazyRoute: typeof AdminLayoutAdminUserHubRolesLazyRoute;
  AdminLayoutAdminUserHubUsersLazyRoute: typeof AdminLayoutAdminUserHubUsersLazyRoute;
  AdminLayoutAdminAnalyticsHubIndexRoute: typeof AdminLayoutAdminAnalyticsHubIndexRoute;
  AdminLayoutAdminConferenceHubIndexRoute: typeof AdminLayoutAdminConferenceHubIndexRoute;
  AdminLayoutAdminOperationsHubIndexRoute: typeof AdminLayoutAdminOperationsHubIndexRoute;
  AdminLayoutAdminUserHubIndexRoute: typeof AdminLayoutAdminUserHubIndexRoute;
  AdminLayoutAdminAdboardsAdboardIdEditRoute: typeof AdminLayoutAdminAdboardsAdboardIdEditRoute;
  AdminLayoutAdminAdboardsAdboardIdPreviewRoute: typeof AdminLayoutAdminAdboardsAdboardIdPreviewRoute;
  AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveRoute: typeof AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveRoute;
  AdminLayoutAdminConferenceHubContentManagerFolderLazyRoute: typeof AdminLayoutAdminConferenceHubContentManagerFolderLazyRoute;
  AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyRoute: typeof AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyRoute;
  AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyRoute: typeof AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyRoute;
  AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexRoute: typeof AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexRoute;
  AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyRoute: typeof AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyRoute;
  AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyRoute: typeof AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyRoute;
  AdminLayoutAdminConferenceHubContentManagerIndexLazyRoute: typeof AdminLayoutAdminConferenceHubContentManagerIndexLazyRoute;
  AdminLayoutAdminConferenceHubMyAdboardsIndexLazyRoute: typeof AdminLayoutAdminConferenceHubMyAdboardsIndexLazyRoute;
  AdminLayoutAdminOperationsHubAseReportingIndexLazyRoute: typeof AdminLayoutAdminOperationsHubAseReportingIndexLazyRoute;
  AdminLayoutAdminOperationsHubPulseIndexLazyRoute: typeof AdminLayoutAdminOperationsHubPulseIndexLazyRoute;
  AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexRoute: typeof AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexRoute;
  AdminLayoutAdminAnalyticsHubUserInsightsUserIndexRoute: typeof AdminLayoutAdminAnalyticsHubUserInsightsUserIndexRoute;
}

const AdminLayoutRouteChildren: AdminLayoutRouteChildren = {
  AdminLayoutAdminIndexLazyRoute: AdminLayoutAdminIndexLazyRoute,
  AdminLayoutAdminAnalyticsHubUserInsightsLazyRoute:
    AdminLayoutAdminAnalyticsHubUserInsightsLazyRoute,
  AdminLayoutAdminFaqHubCategoryLazyRoute:
    AdminLayoutAdminFaqHubCategoryLazyRoute,
  AdminLayoutAdminFaqHubFaqLazyRoute: AdminLayoutAdminFaqHubFaqLazyRoute,
  AdminLayoutAdminUserHubRolesLazyRoute: AdminLayoutAdminUserHubRolesLazyRoute,
  AdminLayoutAdminUserHubUsersLazyRoute: AdminLayoutAdminUserHubUsersLazyRoute,
  AdminLayoutAdminAnalyticsHubIndexRoute:
    AdminLayoutAdminAnalyticsHubIndexRoute,
  AdminLayoutAdminConferenceHubIndexRoute:
    AdminLayoutAdminConferenceHubIndexRoute,
  AdminLayoutAdminOperationsHubIndexRoute:
    AdminLayoutAdminOperationsHubIndexRoute,
  AdminLayoutAdminUserHubIndexRoute: AdminLayoutAdminUserHubIndexRoute,
  AdminLayoutAdminAdboardsAdboardIdEditRoute:
    AdminLayoutAdminAdboardsAdboardIdEditRoute,
  AdminLayoutAdminAdboardsAdboardIdPreviewRoute:
    AdminLayoutAdminAdboardsAdboardIdPreviewRoute,
  AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveRoute:
    AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveRoute,
  AdminLayoutAdminConferenceHubContentManagerFolderLazyRoute:
    AdminLayoutAdminConferenceHubContentManagerFolderLazyRoute,
  AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyRoute:
    AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyRoute,
  AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyRoute:
    AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyRoute,
  AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexRoute:
    AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexRoute,
  AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyRoute:
    AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyRoute,
  AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyRoute:
    AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyRoute,
  AdminLayoutAdminConferenceHubContentManagerIndexLazyRoute:
    AdminLayoutAdminConferenceHubContentManagerIndexLazyRoute,
  AdminLayoutAdminConferenceHubMyAdboardsIndexLazyRoute:
    AdminLayoutAdminConferenceHubMyAdboardsIndexLazyRoute,
  AdminLayoutAdminOperationsHubAseReportingIndexLazyRoute:
    AdminLayoutAdminOperationsHubAseReportingIndexLazyRoute,
  AdminLayoutAdminOperationsHubPulseIndexLazyRoute:
    AdminLayoutAdminOperationsHubPulseIndexLazyRoute,
  AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexRoute:
    AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexRoute,
  AdminLayoutAdminAnalyticsHubUserInsightsUserIndexRoute:
    AdminLayoutAdminAnalyticsHubUserInsightsUserIndexRoute,
};

const AdminLayoutRouteWithChildren = AdminLayoutRoute._addFileChildren(
  AdminLayoutRouteChildren,
);

interface InfoPageLayoutRouteChildren {
  InfoPageLayoutLegalDatenschutzLazyRoute: typeof InfoPageLayoutLegalDatenschutzLazyRoute;
  InfoPageLayoutLegalGrundsaetzeLazyRoute: typeof InfoPageLayoutLegalGrundsaetzeLazyRoute;
}

const InfoPageLayoutRouteChildren: InfoPageLayoutRouteChildren = {
  InfoPageLayoutLegalDatenschutzLazyRoute:
    InfoPageLayoutLegalDatenschutzLazyRoute,
  InfoPageLayoutLegalGrundsaetzeLazyRoute:
    InfoPageLayoutLegalGrundsaetzeLazyRoute,
};

const InfoPageLayoutRouteWithChildren = InfoPageLayoutRoute._addFileChildren(
  InfoPageLayoutRouteChildren,
);

interface LoginLayoutRouteChildren {
  LoginLayoutUserInviteRoute: typeof LoginLayoutUserInviteRoute;
  LoginLayoutChangePasswordLazyRoute: typeof LoginLayoutChangePasswordLazyRoute;
  LoginLayoutForgotPasswordLazyRoute: typeof LoginLayoutForgotPasswordLazyRoute;
  LoginLayoutLoginLazyRoute: typeof LoginLayoutLoginLazyRoute;
  LoginLayoutOtpLazyRoute: typeof LoginLayoutOtpLazyRoute;
}

const LoginLayoutRouteChildren: LoginLayoutRouteChildren = {
  LoginLayoutUserInviteRoute: LoginLayoutUserInviteRoute,
  LoginLayoutChangePasswordLazyRoute: LoginLayoutChangePasswordLazyRoute,
  LoginLayoutForgotPasswordLazyRoute: LoginLayoutForgotPasswordLazyRoute,
  LoginLayoutLoginLazyRoute: LoginLayoutLoginLazyRoute,
  LoginLayoutOtpLazyRoute: LoginLayoutOtpLazyRoute,
};

const LoginLayoutRouteWithChildren = LoginLayoutRoute._addFileChildren(
  LoginLayoutRouteChildren,
);

interface PlatformLayoutRouteChildren {
  PlatformLayoutFeedbackRoute: typeof PlatformLayoutFeedbackRoute;
  PlatformLayoutMyProfileLazyRoute: typeof PlatformLayoutMyProfileLazyRoute;
  PlatformLayoutIndexLazyRoute: typeof PlatformLayoutIndexLazyRoute;
  PlatformLayoutHelpFaqLazyRoute: typeof PlatformLayoutHelpFaqLazyRoute;
  PlatformLayoutHelpIndexLazyRoute: typeof PlatformLayoutHelpIndexLazyRoute;
  PlatformLayoutAdboardAdboardForumRoute: typeof PlatformLayoutAdboardAdboardForumRoute;
  PlatformLayoutAdboardAdboardOnDemandParticipationRoute: typeof PlatformLayoutAdboardAdboardOnDemandParticipationRoute;
  PlatformLayoutAdboardAdboardIndexRoute: typeof PlatformLayoutAdboardAdboardIndexRoute;
}

const PlatformLayoutRouteChildren: PlatformLayoutRouteChildren = {
  PlatformLayoutFeedbackRoute: PlatformLayoutFeedbackRoute,
  PlatformLayoutMyProfileLazyRoute: PlatformLayoutMyProfileLazyRoute,
  PlatformLayoutIndexLazyRoute: PlatformLayoutIndexLazyRoute,
  PlatformLayoutHelpFaqLazyRoute: PlatformLayoutHelpFaqLazyRoute,
  PlatformLayoutHelpIndexLazyRoute: PlatformLayoutHelpIndexLazyRoute,
  PlatformLayoutAdboardAdboardForumRoute:
    PlatformLayoutAdboardAdboardForumRoute,
  PlatformLayoutAdboardAdboardOnDemandParticipationRoute:
    PlatformLayoutAdboardAdboardOnDemandParticipationRoute,
  PlatformLayoutAdboardAdboardIndexRoute:
    PlatformLayoutAdboardAdboardIndexRoute,
};

const PlatformLayoutRouteWithChildren = PlatformLayoutRoute._addFileChildren(
  PlatformLayoutRouteChildren,
);

interface ReducedLayoutRouteChildren {
  ReducedLayoutAdboardAdboardMeetingBreakoutRoute: typeof ReducedLayoutAdboardAdboardMeetingBreakoutRoute;
  ReducedLayoutAdboardAdboardMeetingIndexRoute: typeof ReducedLayoutAdboardAdboardMeetingIndexRoute;
  ReducedLayoutAdboardAdboardForumMeetingMeetingIndexRoute: typeof ReducedLayoutAdboardAdboardForumMeetingMeetingIndexRoute;
}

const ReducedLayoutRouteChildren: ReducedLayoutRouteChildren = {
  ReducedLayoutAdboardAdboardMeetingBreakoutRoute:
    ReducedLayoutAdboardAdboardMeetingBreakoutRoute,
  ReducedLayoutAdboardAdboardMeetingIndexRoute:
    ReducedLayoutAdboardAdboardMeetingIndexRoute,
  ReducedLayoutAdboardAdboardForumMeetingMeetingIndexRoute:
    ReducedLayoutAdboardAdboardForumMeetingMeetingIndexRoute,
};

const ReducedLayoutRouteWithChildren = ReducedLayoutRoute._addFileChildren(
  ReducedLayoutRouteChildren,
);

export interface FileRoutesByFullPath {
  '': typeof ReducedLayoutRouteWithChildren;
  '/user-invite': typeof LoginLayoutUserInviteRoute;
  '/feedback': typeof PlatformLayoutFeedbackRoute;
  '/two-factor-authenticator': typeof R2faLayoutTwoFactorAuthenticatorLazyRoute;
  '/change-password': typeof LoginLayoutChangePasswordLazyRoute;
  '/forgot-password': typeof LoginLayoutForgotPasswordLazyRoute;
  '/login': typeof LoginLayoutLoginLazyRoute;
  '/otp': typeof LoginLayoutOtpLazyRoute;
  '/my-profile': typeof PlatformLayoutMyProfileLazyRoute;
  '/': typeof PlatformLayoutIndexLazyRoute;
  '/legal/datenschutz': typeof InfoPageLayoutLegalDatenschutzLazyRoute;
  '/legal/grundsaetze': typeof InfoPageLayoutLegalGrundsaetzeLazyRoute;
  '/help/faq': typeof PlatformLayoutHelpFaqLazyRoute;
  '/admin': typeof AdminLayoutAdminIndexLazyRoute;
  '/help': typeof PlatformLayoutHelpIndexLazyRoute;
  '/adboard/$adboard/forum': typeof PlatformLayoutAdboardAdboardForumRoute;
  '/adboard/$adboard/on-demand-participation': typeof PlatformLayoutAdboardAdboardOnDemandParticipationRoute;
  '/recording/meeting/$meeting/$token': typeof RecordingMeetingMeetingTokenRoute;
  '/admin/analytics-hub/user-insights': typeof AdminLayoutAdminAnalyticsHubUserInsightsLazyRoute;
  '/admin/faq-hub/category': typeof AdminLayoutAdminFaqHubCategoryLazyRoute;
  '/admin/faq-hub/faq': typeof AdminLayoutAdminFaqHubFaqLazyRoute;
  '/admin/user-hub/roles': typeof AdminLayoutAdminUserHubRolesLazyRoute;
  '/admin/user-hub/users': typeof AdminLayoutAdminUserHubUsersLazyRoute;
  '/admin/analytics-hub': typeof AdminLayoutAdminAnalyticsHubIndexRoute;
  '/admin/conference-hub': typeof AdminLayoutAdminConferenceHubIndexRoute;
  '/admin/operations-hub': typeof AdminLayoutAdminOperationsHubIndexRoute;
  '/admin/user-hub': typeof AdminLayoutAdminUserHubIndexRoute;
  '/adboard/$adboard': typeof PlatformLayoutAdboardAdboardIndexRoute;
  '/admin/adboards/$adboardId/edit': typeof AdminLayoutAdminAdboardsAdboardIdEditRoute;
  '/admin/adboards/$adboardId/preview': typeof AdminLayoutAdminAdboardsAdboardIdPreviewRoute;
  '/admin/analytics-hub/adboard-insights/archive': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveRoute;
  '/adboard/$adboard/meeting/$breakout': typeof ReducedLayoutAdboardAdboardMeetingBreakoutRoute;
  '/admin/conference-hub/content-manager/$folder': typeof AdminLayoutAdminConferenceHubContentManagerFolderLazyRoute;
  '/admin/conference-hub/my-adboards/archive': typeof AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyRoute;
  '/admin/conference-hub/my-adboards/drafts': typeof AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyRoute;
  '/admin/analytics-hub/feedback-insights': typeof AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexRoute;
  '/adboard/$adboard/meeting': typeof ReducedLayoutAdboardAdboardMeetingIndexRoute;
  '/admin/analytics-hub/adboard-insights': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyRoute;
  '/admin/analytics-hub/platform-insights': typeof AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyRoute;
  '/admin/conference-hub/content-manager': typeof AdminLayoutAdminConferenceHubContentManagerIndexLazyRoute;
  '/admin/conference-hub/my-adboards': typeof AdminLayoutAdminConferenceHubMyAdboardsIndexLazyRoute;
  '/admin/operations-hub/ase-reporting': typeof AdminLayoutAdminOperationsHubAseReportingIndexLazyRoute;
  '/admin/operations-hub/pulse': typeof AdminLayoutAdminOperationsHubPulseIndexLazyRoute;
  '/admin/analytics-hub/adboard-insights/$adboard': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexRoute;
  '/admin/analytics-hub/user-insights/$user': typeof AdminLayoutAdminAnalyticsHubUserInsightsUserIndexRoute;
  '/adboard/$adboard/forum/meeting/$meeting': typeof ReducedLayoutAdboardAdboardForumMeetingMeetingIndexRoute;
}

export interface FileRoutesByTo {
  '': typeof ReducedLayoutRouteWithChildren;
  '/user-invite': typeof LoginLayoutUserInviteRoute;
  '/feedback': typeof PlatformLayoutFeedbackRoute;
  '/two-factor-authenticator': typeof R2faLayoutTwoFactorAuthenticatorLazyRoute;
  '/change-password': typeof LoginLayoutChangePasswordLazyRoute;
  '/forgot-password': typeof LoginLayoutForgotPasswordLazyRoute;
  '/login': typeof LoginLayoutLoginLazyRoute;
  '/otp': typeof LoginLayoutOtpLazyRoute;
  '/my-profile': typeof PlatformLayoutMyProfileLazyRoute;
  '/': typeof PlatformLayoutIndexLazyRoute;
  '/legal/datenschutz': typeof InfoPageLayoutLegalDatenschutzLazyRoute;
  '/legal/grundsaetze': typeof InfoPageLayoutLegalGrundsaetzeLazyRoute;
  '/help/faq': typeof PlatformLayoutHelpFaqLazyRoute;
  '/admin': typeof AdminLayoutAdminIndexLazyRoute;
  '/help': typeof PlatformLayoutHelpIndexLazyRoute;
  '/adboard/$adboard/forum': typeof PlatformLayoutAdboardAdboardForumRoute;
  '/adboard/$adboard/on-demand-participation': typeof PlatformLayoutAdboardAdboardOnDemandParticipationRoute;
  '/recording/meeting/$meeting/$token': typeof RecordingMeetingMeetingTokenRoute;
  '/admin/analytics-hub/user-insights': typeof AdminLayoutAdminAnalyticsHubUserInsightsLazyRoute;
  '/admin/faq-hub/category': typeof AdminLayoutAdminFaqHubCategoryLazyRoute;
  '/admin/faq-hub/faq': typeof AdminLayoutAdminFaqHubFaqLazyRoute;
  '/admin/user-hub/roles': typeof AdminLayoutAdminUserHubRolesLazyRoute;
  '/admin/user-hub/users': typeof AdminLayoutAdminUserHubUsersLazyRoute;
  '/admin/analytics-hub': typeof AdminLayoutAdminAnalyticsHubIndexRoute;
  '/admin/conference-hub': typeof AdminLayoutAdminConferenceHubIndexRoute;
  '/admin/operations-hub': typeof AdminLayoutAdminOperationsHubIndexRoute;
  '/admin/user-hub': typeof AdminLayoutAdminUserHubIndexRoute;
  '/adboard/$adboard': typeof PlatformLayoutAdboardAdboardIndexRoute;
  '/admin/adboards/$adboardId/edit': typeof AdminLayoutAdminAdboardsAdboardIdEditRoute;
  '/admin/adboards/$adboardId/preview': typeof AdminLayoutAdminAdboardsAdboardIdPreviewRoute;
  '/admin/analytics-hub/adboard-insights/archive': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveRoute;
  '/adboard/$adboard/meeting/$breakout': typeof ReducedLayoutAdboardAdboardMeetingBreakoutRoute;
  '/admin/conference-hub/content-manager/$folder': typeof AdminLayoutAdminConferenceHubContentManagerFolderLazyRoute;
  '/admin/conference-hub/my-adboards/archive': typeof AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyRoute;
  '/admin/conference-hub/my-adboards/drafts': typeof AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyRoute;
  '/admin/analytics-hub/feedback-insights': typeof AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexRoute;
  '/adboard/$adboard/meeting': typeof ReducedLayoutAdboardAdboardMeetingIndexRoute;
  '/admin/analytics-hub/adboard-insights': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyRoute;
  '/admin/analytics-hub/platform-insights': typeof AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyRoute;
  '/admin/conference-hub/content-manager': typeof AdminLayoutAdminConferenceHubContentManagerIndexLazyRoute;
  '/admin/conference-hub/my-adboards': typeof AdminLayoutAdminConferenceHubMyAdboardsIndexLazyRoute;
  '/admin/operations-hub/ase-reporting': typeof AdminLayoutAdminOperationsHubAseReportingIndexLazyRoute;
  '/admin/operations-hub/pulse': typeof AdminLayoutAdminOperationsHubPulseIndexLazyRoute;
  '/admin/analytics-hub/adboard-insights/$adboard': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexRoute;
  '/admin/analytics-hub/user-insights/$user': typeof AdminLayoutAdminAnalyticsHubUserInsightsUserIndexRoute;
  '/adboard/$adboard/forum/meeting/$meeting': typeof ReducedLayoutAdboardAdboardForumMeetingMeetingIndexRoute;
}

export interface FileRoutesById {
  __root__: typeof rootRoute;
  '/_2fa-layout': typeof R2faLayoutRouteWithChildren;
  '/_admin-layout': typeof AdminLayoutRouteWithChildren;
  '/_info-page-layout': typeof InfoPageLayoutRouteWithChildren;
  '/_login-layout': typeof LoginLayoutRouteWithChildren;
  '/_platform-layout': typeof PlatformLayoutRouteWithChildren;
  '/_reduced-layout': typeof ReducedLayoutRouteWithChildren;
  '/_login-layout/user-invite': typeof LoginLayoutUserInviteRoute;
  '/_platform-layout/feedback_': typeof PlatformLayoutFeedbackRoute;
  '/_2fa-layout/two-factor-authenticator': typeof R2faLayoutTwoFactorAuthenticatorLazyRoute;
  '/_login-layout/change-password': typeof LoginLayoutChangePasswordLazyRoute;
  '/_login-layout/forgot-password': typeof LoginLayoutForgotPasswordLazyRoute;
  '/_login-layout/login': typeof LoginLayoutLoginLazyRoute;
  '/_login-layout/otp': typeof LoginLayoutOtpLazyRoute;
  '/_platform-layout/my-profile': typeof PlatformLayoutMyProfileLazyRoute;
  '/_platform-layout/': typeof PlatformLayoutIndexLazyRoute;
  '/_info-page-layout/legal/datenschutz': typeof InfoPageLayoutLegalDatenschutzLazyRoute;
  '/_info-page-layout/legal/grundsaetze': typeof InfoPageLayoutLegalGrundsaetzeLazyRoute;
  '/_platform-layout/help/faq': typeof PlatformLayoutHelpFaqLazyRoute;
  '/_admin-layout/admin/': typeof AdminLayoutAdminIndexLazyRoute;
  '/_platform-layout/help/': typeof PlatformLayoutHelpIndexLazyRoute;
  '/_platform-layout/adboard/$adboard/forum_': typeof PlatformLayoutAdboardAdboardForumRoute;
  '/_platform-layout/adboard/$adboard/on-demand-participation_': typeof PlatformLayoutAdboardAdboardOnDemandParticipationRoute;
  '/recording/meeting/$meeting/$token': typeof RecordingMeetingMeetingTokenRoute;
  '/_admin-layout/admin/analytics-hub/user-insights': typeof AdminLayoutAdminAnalyticsHubUserInsightsLazyRoute;
  '/_admin-layout/admin/faq-hub/category': typeof AdminLayoutAdminFaqHubCategoryLazyRoute;
  '/_admin-layout/admin/faq-hub/faq': typeof AdminLayoutAdminFaqHubFaqLazyRoute;
  '/_admin-layout/admin/user-hub/roles': typeof AdminLayoutAdminUserHubRolesLazyRoute;
  '/_admin-layout/admin/user-hub/users': typeof AdminLayoutAdminUserHubUsersLazyRoute;
  '/_admin-layout/admin/analytics-hub/': typeof AdminLayoutAdminAnalyticsHubIndexRoute;
  '/_admin-layout/admin/conference-hub/': typeof AdminLayoutAdminConferenceHubIndexRoute;
  '/_admin-layout/admin/operations-hub/': typeof AdminLayoutAdminOperationsHubIndexRoute;
  '/_admin-layout/admin/user-hub/': typeof AdminLayoutAdminUserHubIndexRoute;
  '/_platform-layout/adboard/$adboard/': typeof PlatformLayoutAdboardAdboardIndexRoute;
  '/_admin-layout/admin/adboards_/$adboardId/edit': typeof AdminLayoutAdminAdboardsAdboardIdEditRoute;
  '/_admin-layout/admin/adboards_/$adboardId/preview': typeof AdminLayoutAdminAdboardsAdboardIdPreviewRoute;
  '/_admin-layout/admin/analytics-hub/adboard-insights/archive': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsArchiveRoute;
  '/_reduced-layout/adboard/$adboard/meeting/$breakout': typeof ReducedLayoutAdboardAdboardMeetingBreakoutRoute;
  '/_admin-layout/admin/conference-hub/content-manager_/$folder': typeof AdminLayoutAdminConferenceHubContentManagerFolderLazyRoute;
  '/_admin-layout/admin/conference-hub/my-adboards_/archive': typeof AdminLayoutAdminConferenceHubMyAdboardsArchiveLazyRoute;
  '/_admin-layout/admin/conference-hub/my-adboards_/drafts': typeof AdminLayoutAdminConferenceHubMyAdboardsDraftsLazyRoute;
  '/_admin-layout/admin/analytics-hub/feedback-insights/': typeof AdminLayoutAdminAnalyticsHubFeedbackInsightsIndexRoute;
  '/_reduced-layout/adboard/$adboard/meeting/': typeof ReducedLayoutAdboardAdboardMeetingIndexRoute;
  '/_admin-layout/admin/analytics-hub/adboard-insights/': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsIndexLazyRoute;
  '/_admin-layout/admin/analytics-hub/platform-insights_/': typeof AdminLayoutAdminAnalyticsHubPlatformInsightsIndexLazyRoute;
  '/_admin-layout/admin/conference-hub/content-manager_/': typeof AdminLayoutAdminConferenceHubContentManagerIndexLazyRoute;
  '/_admin-layout/admin/conference-hub/my-adboards_/': typeof AdminLayoutAdminConferenceHubMyAdboardsIndexLazyRoute;
  '/_admin-layout/admin/operations-hub/ase-reporting_/': typeof AdminLayoutAdminOperationsHubAseReportingIndexLazyRoute;
  '/_admin-layout/admin/operations-hub/pulse_/': typeof AdminLayoutAdminOperationsHubPulseIndexLazyRoute;
  '/_admin-layout/admin/analytics-hub/adboard-insights/$adboard/': typeof AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndexRoute;
  '/_admin-layout/admin/analytics-hub/user-insights_/$user/': typeof AdminLayoutAdminAnalyticsHubUserInsightsUserIndexRoute;
  '/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/': typeof ReducedLayoutAdboardAdboardForumMeetingMeetingIndexRoute;
}

export interface FileRouteTypes {
  fileRoutesByFullPath: FileRoutesByFullPath;
  fullPaths:
    | ''
    | '/user-invite'
    | '/feedback'
    | '/two-factor-authenticator'
    | '/change-password'
    | '/forgot-password'
    | '/login'
    | '/otp'
    | '/my-profile'
    | '/'
    | '/legal/datenschutz'
    | '/legal/grundsaetze'
    | '/help/faq'
    | '/admin'
    | '/help'
    | '/adboard/$adboard/forum'
    | '/adboard/$adboard/on-demand-participation'
    | '/recording/meeting/$meeting/$token'
    | '/admin/analytics-hub/user-insights'
    | '/admin/faq-hub/category'
    | '/admin/faq-hub/faq'
    | '/admin/user-hub/roles'
    | '/admin/user-hub/users'
    | '/admin/analytics-hub'
    | '/admin/conference-hub'
    | '/admin/operations-hub'
    | '/admin/user-hub'
    | '/adboard/$adboard'
    | '/admin/adboards/$adboardId/edit'
    | '/admin/adboards/$adboardId/preview'
    | '/admin/analytics-hub/adboard-insights/archive'
    | '/adboard/$adboard/meeting/$breakout'
    | '/admin/conference-hub/content-manager/$folder'
    | '/admin/conference-hub/my-adboards/archive'
    | '/admin/conference-hub/my-adboards/drafts'
    | '/admin/analytics-hub/feedback-insights'
    | '/adboard/$adboard/meeting'
    | '/admin/analytics-hub/adboard-insights'
    | '/admin/analytics-hub/platform-insights'
    | '/admin/conference-hub/content-manager'
    | '/admin/conference-hub/my-adboards'
    | '/admin/operations-hub/ase-reporting'
    | '/admin/operations-hub/pulse'
    | '/admin/analytics-hub/adboard-insights/$adboard'
    | '/admin/analytics-hub/user-insights/$user'
    | '/adboard/$adboard/forum/meeting/$meeting';
  fileRoutesByTo: FileRoutesByTo;
  to:
    | ''
    | '/user-invite'
    | '/feedback'
    | '/two-factor-authenticator'
    | '/change-password'
    | '/forgot-password'
    | '/login'
    | '/otp'
    | '/my-profile'
    | '/'
    | '/legal/datenschutz'
    | '/legal/grundsaetze'
    | '/help/faq'
    | '/admin'
    | '/help'
    | '/adboard/$adboard/forum'
    | '/adboard/$adboard/on-demand-participation'
    | '/recording/meeting/$meeting/$token'
    | '/admin/analytics-hub/user-insights'
    | '/admin/faq-hub/category'
    | '/admin/faq-hub/faq'
    | '/admin/user-hub/roles'
    | '/admin/user-hub/users'
    | '/admin/analytics-hub'
    | '/admin/conference-hub'
    | '/admin/operations-hub'
    | '/admin/user-hub'
    | '/adboard/$adboard'
    | '/admin/adboards/$adboardId/edit'
    | '/admin/adboards/$adboardId/preview'
    | '/admin/analytics-hub/adboard-insights/archive'
    | '/adboard/$adboard/meeting/$breakout'
    | '/admin/conference-hub/content-manager/$folder'
    | '/admin/conference-hub/my-adboards/archive'
    | '/admin/conference-hub/my-adboards/drafts'
    | '/admin/analytics-hub/feedback-insights'
    | '/adboard/$adboard/meeting'
    | '/admin/analytics-hub/adboard-insights'
    | '/admin/analytics-hub/platform-insights'
    | '/admin/conference-hub/content-manager'
    | '/admin/conference-hub/my-adboards'
    | '/admin/operations-hub/ase-reporting'
    | '/admin/operations-hub/pulse'
    | '/admin/analytics-hub/adboard-insights/$adboard'
    | '/admin/analytics-hub/user-insights/$user'
    | '/adboard/$adboard/forum/meeting/$meeting';
  id:
    | '__root__'
    | '/_2fa-layout'
    | '/_admin-layout'
    | '/_info-page-layout'
    | '/_login-layout'
    | '/_platform-layout'
    | '/_reduced-layout'
    | '/_login-layout/user-invite'
    | '/_platform-layout/feedback_'
    | '/_2fa-layout/two-factor-authenticator'
    | '/_login-layout/change-password'
    | '/_login-layout/forgot-password'
    | '/_login-layout/login'
    | '/_login-layout/otp'
    | '/_platform-layout/my-profile'
    | '/_platform-layout/'
    | '/_info-page-layout/legal/datenschutz'
    | '/_info-page-layout/legal/grundsaetze'
    | '/_platform-layout/help/faq'
    | '/_admin-layout/admin/'
    | '/_platform-layout/help/'
    | '/_platform-layout/adboard/$adboard/forum_'
    | '/_platform-layout/adboard/$adboard/on-demand-participation_'
    | '/recording/meeting/$meeting/$token'
    | '/_admin-layout/admin/analytics-hub/user-insights'
    | '/_admin-layout/admin/faq-hub/category'
    | '/_admin-layout/admin/faq-hub/faq'
    | '/_admin-layout/admin/user-hub/roles'
    | '/_admin-layout/admin/user-hub/users'
    | '/_admin-layout/admin/analytics-hub/'
    | '/_admin-layout/admin/conference-hub/'
    | '/_admin-layout/admin/operations-hub/'
    | '/_admin-layout/admin/user-hub/'
    | '/_platform-layout/adboard/$adboard/'
    | '/_admin-layout/admin/adboards_/$adboardId/edit'
    | '/_admin-layout/admin/adboards_/$adboardId/preview'
    | '/_admin-layout/admin/analytics-hub/adboard-insights/archive'
    | '/_reduced-layout/adboard/$adboard/meeting/$breakout'
    | '/_admin-layout/admin/conference-hub/content-manager_/$folder'
    | '/_admin-layout/admin/conference-hub/my-adboards_/archive'
    | '/_admin-layout/admin/conference-hub/my-adboards_/drafts'
    | '/_admin-layout/admin/analytics-hub/feedback-insights/'
    | '/_reduced-layout/adboard/$adboard/meeting/'
    | '/_admin-layout/admin/analytics-hub/adboard-insights/'
    | '/_admin-layout/admin/analytics-hub/platform-insights_/'
    | '/_admin-layout/admin/conference-hub/content-manager_/'
    | '/_admin-layout/admin/conference-hub/my-adboards_/'
    | '/_admin-layout/admin/operations-hub/ase-reporting_/'
    | '/_admin-layout/admin/operations-hub/pulse_/'
    | '/_admin-layout/admin/analytics-hub/adboard-insights/$adboard/'
    | '/_admin-layout/admin/analytics-hub/user-insights_/$user/'
    | '/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/';
  fileRoutesById: FileRoutesById;
}

export interface RootRouteChildren {
  R2faLayoutRoute: typeof R2faLayoutRouteWithChildren;
  AdminLayoutRoute: typeof AdminLayoutRouteWithChildren;
  InfoPageLayoutRoute: typeof InfoPageLayoutRouteWithChildren;
  LoginLayoutRoute: typeof LoginLayoutRouteWithChildren;
  PlatformLayoutRoute: typeof PlatformLayoutRouteWithChildren;
  ReducedLayoutRoute: typeof ReducedLayoutRouteWithChildren;
  RecordingMeetingMeetingTokenRoute: typeof RecordingMeetingMeetingTokenRoute;
}

const rootRouteChildren: RootRouteChildren = {
  R2faLayoutRoute: R2faLayoutRouteWithChildren,
  AdminLayoutRoute: AdminLayoutRouteWithChildren,
  InfoPageLayoutRoute: InfoPageLayoutRouteWithChildren,
  LoginLayoutRoute: LoginLayoutRouteWithChildren,
  PlatformLayoutRoute: PlatformLayoutRouteWithChildren,
  ReducedLayoutRoute: ReducedLayoutRouteWithChildren,
  RecordingMeetingMeetingTokenRoute: RecordingMeetingMeetingTokenRoute,
};

export const routeTree = rootRoute
  ._addFileChildren(rootRouteChildren)
  ._addFileTypes<FileRouteTypes>();

/* ROUTE_MANIFEST_START
{
  "routes": {
    "__root__": {
      "filePath": "__root.jsx",
      "children": [
        "/_2fa-layout",
        "/_admin-layout",
        "/_info-page-layout",
        "/_login-layout",
        "/_platform-layout",
        "/_reduced-layout",
        "/recording/meeting/$meeting/$token"
      ]
    },
    "/_2fa-layout": {
      "filePath": "_2fa-layout.jsx",
      "children": [
        "/_2fa-layout/two-factor-authenticator"
      ]
    },
    "/_admin-layout": {
      "filePath": "_admin-layout.jsx",
      "children": [
        "/_admin-layout/admin/",
        "/_admin-layout/admin/analytics-hub/user-insights",
        "/_admin-layout/admin/faq-hub/category",
        "/_admin-layout/admin/faq-hub/faq",
        "/_admin-layout/admin/user-hub/roles",
        "/_admin-layout/admin/user-hub/users",
        "/_admin-layout/admin/analytics-hub/",
        "/_admin-layout/admin/conference-hub/",
        "/_admin-layout/admin/operations-hub/",
        "/_admin-layout/admin/user-hub/",
        "/_admin-layout/admin/adboards_/$adboardId/edit",
        "/_admin-layout/admin/adboards_/$adboardId/preview",
        "/_admin-layout/admin/analytics-hub/adboard-insights/archive",
        "/_admin-layout/admin/conference-hub/content-manager_/$folder",
        "/_admin-layout/admin/conference-hub/my-adboards_/archive",
        "/_admin-layout/admin/conference-hub/my-adboards_/drafts",
        "/_admin-layout/admin/analytics-hub/feedback-insights/",
        "/_admin-layout/admin/analytics-hub/adboard-insights/",
        "/_admin-layout/admin/analytics-hub/platform-insights_/",
        "/_admin-layout/admin/conference-hub/content-manager_/",
        "/_admin-layout/admin/conference-hub/my-adboards_/",
        "/_admin-layout/admin/operations-hub/ase-reporting_/",
        "/_admin-layout/admin/operations-hub/pulse_/",
        "/_admin-layout/admin/analytics-hub/adboard-insights/$adboard/",
        "/_admin-layout/admin/analytics-hub/user-insights_/$user/"
      ]
    },
    "/_info-page-layout": {
      "filePath": "_info-page-layout.jsx",
      "children": [
        "/_info-page-layout/legal/datenschutz",
        "/_info-page-layout/legal/grundsaetze"
      ]
    },
    "/_login-layout": {
      "filePath": "_login-layout.jsx",
      "children": [
        "/_login-layout/user-invite",
        "/_login-layout/change-password",
        "/_login-layout/forgot-password",
        "/_login-layout/login",
        "/_login-layout/otp"
      ]
    },
    "/_platform-layout": {
      "filePath": "_platform-layout.jsx",
      "children": [
        "/_platform-layout/feedback_",
        "/_platform-layout/my-profile",
        "/_platform-layout/",
        "/_platform-layout/help/faq",
        "/_platform-layout/help/",
        "/_platform-layout/adboard/$adboard/forum_",
        "/_platform-layout/adboard/$adboard/on-demand-participation_",
        "/_platform-layout/adboard/$adboard/"
      ]
    },
    "/_reduced-layout": {
      "filePath": "_reduced-layout.jsx",
      "children": [
        "/_reduced-layout/adboard/$adboard/meeting/$breakout",
        "/_reduced-layout/adboard/$adboard/meeting/",
        "/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/"
      ]
    },
    "/_login-layout/user-invite": {
      "filePath": "_login-layout/user-invite.jsx",
      "parent": "/_login-layout"
    },
    "/_platform-layout/feedback_": {
      "filePath": "_platform-layout/feedback_.jsx",
      "parent": "/_platform-layout"
    },
    "/_2fa-layout/two-factor-authenticator": {
      "filePath": "_2fa-layout/two-factor-authenticator.lazy.jsx",
      "parent": "/_2fa-layout"
    },
    "/_login-layout/change-password": {
      "filePath": "_login-layout/change-password.lazy.jsx",
      "parent": "/_login-layout"
    },
    "/_login-layout/forgot-password": {
      "filePath": "_login-layout/forgot-password.lazy.jsx",
      "parent": "/_login-layout"
    },
    "/_login-layout/login": {
      "filePath": "_login-layout/login.lazy.jsx",
      "parent": "/_login-layout"
    },
    "/_login-layout/otp": {
      "filePath": "_login-layout/otp.lazy.jsx",
      "parent": "/_login-layout"
    },
    "/_platform-layout/my-profile": {
      "filePath": "_platform-layout/my-profile.lazy.jsx",
      "parent": "/_platform-layout"
    },
    "/_platform-layout/": {
      "filePath": "_platform-layout/index.lazy.jsx",
      "parent": "/_platform-layout"
    },
    "/_info-page-layout/legal/datenschutz": {
      "filePath": "_info-page-layout/legal/datenschutz.lazy.jsx",
      "parent": "/_info-page-layout"
    },
    "/_info-page-layout/legal/grundsaetze": {
      "filePath": "_info-page-layout/legal/grundsaetze.lazy.jsx",
      "parent": "/_info-page-layout"
    },
    "/_platform-layout/help/faq": {
      "filePath": "_platform-layout/help/faq.lazy.jsx",
      "parent": "/_platform-layout"
    },
    "/_admin-layout/admin/": {
      "filePath": "_admin-layout/admin/index.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_platform-layout/help/": {
      "filePath": "_platform-layout/help/index.lazy.jsx",
      "parent": "/_platform-layout"
    },
    "/_platform-layout/adboard/$adboard/forum_": {
      "filePath": "_platform-layout/adboard/$adboard/forum_.jsx",
      "parent": "/_platform-layout"
    },
    "/_platform-layout/adboard/$adboard/on-demand-participation_": {
      "filePath": "_platform-layout/adboard/$adboard/on-demand-participation_.jsx",
      "parent": "/_platform-layout"
    },
    "/recording/meeting/$meeting/$token": {
      "filePath": "recording/meeting/$meeting/$token.jsx"
    },
    "/_admin-layout/admin/analytics-hub/user-insights": {
      "filePath": "_admin-layout/admin/analytics-hub/user-insights.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/faq-hub/category": {
      "filePath": "_admin-layout/admin/faq-hub/category.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/faq-hub/faq": {
      "filePath": "_admin-layout/admin/faq-hub/faq.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/user-hub/roles": {
      "filePath": "_admin-layout/admin/user-hub/roles.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/user-hub/users": {
      "filePath": "_admin-layout/admin/user-hub/users.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/analytics-hub/": {
      "filePath": "_admin-layout/admin/analytics-hub/index.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/conference-hub/": {
      "filePath": "_admin-layout/admin/conference-hub/index.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/operations-hub/": {
      "filePath": "_admin-layout/admin/operations-hub/index.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/user-hub/": {
      "filePath": "_admin-layout/admin/user-hub/index.jsx",
      "parent": "/_admin-layout"
    },
    "/_platform-layout/adboard/$adboard/": {
      "filePath": "_platform-layout/adboard/$adboard/index.jsx",
      "parent": "/_platform-layout"
    },
    "/_admin-layout/admin/adboards_/$adboardId/edit": {
      "filePath": "_admin-layout/admin/adboards_/$adboardId/edit.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/adboards_/$adboardId/preview": {
      "filePath": "_admin-layout/admin/adboards_/$adboardId/preview.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/analytics-hub/adboard-insights/archive": {
      "filePath": "_admin-layout/admin/analytics-hub/adboard-insights/archive.jsx",
      "parent": "/_admin-layout"
    },
    "/_reduced-layout/adboard/$adboard/meeting/$breakout": {
      "filePath": "_reduced-layout/adboard/$adboard/meeting/$breakout.jsx",
      "parent": "/_reduced-layout"
    },
    "/_admin-layout/admin/conference-hub/content-manager_/$folder": {
      "filePath": "_admin-layout/admin/conference-hub/content-manager_/$folder.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/conference-hub/my-adboards_/archive": {
      "filePath": "_admin-layout/admin/conference-hub/my-adboards_/archive.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/conference-hub/my-adboards_/drafts": {
      "filePath": "_admin-layout/admin/conference-hub/my-adboards_/drafts.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/analytics-hub/feedback-insights/": {
      "filePath": "_admin-layout/admin/analytics-hub/feedback-insights/index.jsx",
      "parent": "/_admin-layout"
    },
    "/_reduced-layout/adboard/$adboard/meeting/": {
      "filePath": "_reduced-layout/adboard/$adboard/meeting/index.jsx",
      "parent": "/_reduced-layout"
    },
    "/_admin-layout/admin/analytics-hub/adboard-insights/": {
      "filePath": "_admin-layout/admin/analytics-hub/adboard-insights/index.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/analytics-hub/platform-insights_/": {
      "filePath": "_admin-layout/admin/analytics-hub/platform-insights_/index.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/conference-hub/content-manager_/": {
      "filePath": "_admin-layout/admin/conference-hub/content-manager_/index.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/conference-hub/my-adboards_/": {
      "filePath": "_admin-layout/admin/conference-hub/my-adboards_/index.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/operations-hub/ase-reporting_/": {
      "filePath": "_admin-layout/admin/operations-hub/ase-reporting_/index.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/operations-hub/pulse_/": {
      "filePath": "_admin-layout/admin/operations-hub/pulse_/index.lazy.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/analytics-hub/adboard-insights/$adboard/": {
      "filePath": "_admin-layout/admin/analytics-hub/adboard-insights/$adboard/index.jsx",
      "parent": "/_admin-layout"
    },
    "/_admin-layout/admin/analytics-hub/user-insights_/$user/": {
      "filePath": "_admin-layout/admin/analytics-hub/user-insights_/$user/index.jsx",
      "parent": "/_admin-layout"
    },
    "/_reduced-layout/adboard/$adboard/forum/meeting/$meeting/": {
      "filePath": "_reduced-layout/adboard/$adboard/forum/meeting/$meeting/index.jsx",
      "parent": "/_reduced-layout"
    }
  }
}
ROUTE_MANIFEST_END */
