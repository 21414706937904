import React, {
  useCallback, useEffect, useRef, useState,
} from 'react';
import { Scrollbars } from 'react-custom-scrollbars-2';
import { cn } from '@/lib/utils';
import { useLocation, useMatchRoute, useRouter } from '@tanstack/react-router';
import useCopyRef from '@/components/Hooks/useCopyRef.js';

const renderTrackHorizontal = ({ style, ...props }) => {
  const trackStyle = {
    position: 'absolute',
    height: '6px',
    bottom: '2px',
    left: '2px',
    borderRadius: '9999px',
    right: '2px',
    zIndex: 9999,
  };
  return <div style={{ ...style, ...trackStyle }} {...props} />;
};

const renderTrackVertical = ({ style, ...props }) => {
  const trackStyle = {
    position: 'absolute',
    width: '6px',
    top: '2px',
    right: '2px',
    borderRadius: '9999px',
    bottom: '2px',
    zIndex: 9999,
  };
  return <div style={{ ...style, ...trackStyle }} {...props} />;
};

const renderThumb = ({ style, ...props }) => {
  const thumbStyle = {
    zIndex: 9999,
    background: 'linear-gradient(180deg, #FFC100, #FF585D)',
    borderRadius: '9999px',
  };
  return <div style={{ ...style, ...thumbStyle }} {...props} />;
};

const CustomScrollbar = React.forwardRef(({ children, className, ...props }, ref) => {
  const [lastLocation, setLastLocation] = useState(null);
  const location = useLocation();
  const router = useRouter();

  const scrollRef = useCopyRef(ref, null);

  const handlePageChange = useCallback(async () => {
    if (!router || !router.resetNextScroll) return;
    if (router.latestLoadPromise) await router.latestLoadPromise;
    scrollRef.current?.scrollToTop();
  }, [router, scrollRef]);

  const handleScroll = useCallback(() => {
    if (!scrollRef.current || !scrollRef.current.container) return;
    scrollRef.current.container.scrollTop = 0;
  }, [scrollRef]);

  useEffect(() => {
    if (!scrollRef.current || !scrollRef.current.container) return () => {};
    scrollRef.current.container.addEventListener('scroll', handleScroll);
    return () => {
      if (!scrollRef.current || !scrollRef.current.container) return;
      scrollRef.current.container.removeEventListener('scroll', handleScroll);
    };
  }, [scrollRef, handleScroll]);

  useEffect(() => {
    if (lastLocation === location?.pathname) return;
    setLastLocation(location.pathname);
    handlePageChange();
  }, [location, lastLocation, handlePageChange]);

  return (
    <Scrollbars
      ref={scrollRef}
      style={{ width: '100%', height: '100%' }}
      className={cn('w-full h-full', className)}
      autoHide
      autoHideTimeout={1000}
      autoHideDuration={200}
      renderThumbVertical={renderThumb}
      renderThumbHorizontal={renderThumb}
      renderTrackVertical={renderTrackVertical}
      renderTrackHorizontal={renderTrackHorizontal}
      {...props}
    >
      {children}
    </Scrollbars>
  );
});
CustomScrollbar.displayName = 'CustomScrollbar';

export default CustomScrollbar;
