import { createFileRoute, notFound } from '@tanstack/react-router';
import { mutationFn } from '@/components/Hooks/useApiMutation';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';

export const Route = createFileRoute(
  '/_platform-layout/adboard/$adboard/on-demand-participation_',
)({
  loader: async ({ params: { adboard: adboardId }, context }) => {
    const id = adboardId.split('-').pop();
    const data = await mutationFn(
      'GET',
      [AuthMiddleware],
      { endpoint: `/api/adboards/${id}` },
      context,
    );
    const { adboard } = data.data;
    const onDemand = adboard.on_demand;
    const {
      enabled,
      is_participant: isParticipant,
      available_until: availableUntil,
    } = onDemand;

    if (!enabled || !isParticipant || new Date(availableUntil) < new Date()) {
      throw notFound();
    }

    return data.data.adboard;
  },
});
