import { createFileRoute } from '@tanstack/react-router';
import { mutationFn } from '@/components/Hooks/useApiMutation';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';

export const Route = createFileRoute('/recording/meeting/$meeting/$token')({
  beforeLoad: async ({ params: { token } }) => {
    localStorage.setItem('token', token);
  },
  loader: async ({ params: { meeting: meetingId, token }, context }) => {
    localStorage.setItem('token', token);
    const data = await mutationFn('GET', [AuthMiddleware], { endpoint: `/api/meetings/${meetingId}` }, context);
    const { meeting } = data.data;
    return {
      meeting,
    };
  },
  loaderDeps: () => [Date.now()], // This is a workaround to force the loader to run every time the route is navigated to
});
