import {
  createFileRoute, Outlet, redirect,
} from '@tanstack/react-router';
import React, { Suspense, useEffect } from 'react';
import TransitionLayout from '@/components/Animations/TransitionLayout.jsx';
import { FooterLinks } from '@/components/Home/Footer.jsx';
import {
  PlatformLayoutIndex as HomeRoute,
} from '@/routePaths.gen.js';

// eslint-disable-next-line import/no-unresolved
import { useIdleTimerContext } from 'react-idle-timer';
import LoginBackgroundShapeImage from '../../assets/shapes/login-background-shape.png?format=webp';
import NovartisLogoImage from '../../assets/logos/novartis-logo-rgb.svg';

function Layout() {
  const { pause, resume } = useIdleTimerContext();

  useEffect(() => {
    pause();
    return () => {
      resume();
    };
  }, [pause, resume]);

  return (
    <Suspense fallback={null}>
      <TransitionLayout className="container flex h-screen flex-col p-10 font-body max-xl:px-4">
        <img
          src={NovartisLogoImage}
          alt="Novartis Logo"
          className="h-4 min-h-14 md:top-auto md:h-16 md:self-start lg:block"
        />
        <div className="ml-0 flex h-screen flex-col justify-between max-xl:mx-auto md:ml-36">
          <div className="mt-auto flex max-w-[465px] flex-col">
            <Outlet />
          </div>
          <div className="mt-auto flex flex-col text-center md:flex-row md:gap-5">
            <FooterLinks className="text-black" />
          </div>
        </div>
        <div
          className="pointer-events-none absolute right-0 top-0 hidden h-screen w-[200vw] xl:block portrait:hidden"
          style={{
            backgroundImage: `url(${LoginBackgroundShapeImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right bottom',
            backgroundSize: 'contain',
          }}
        />
      </TransitionLayout>
    </Suspense>
  );
}

const loader = async ({ context, location }) => {
  await context.user.ready().catch(() => {});

  if (context.auth.isAuthenticated()) {
    const search = new URLSearchParams(location.search);
    throw redirect({
      to: HomeRoute,
      search: location.search ? Object.fromEntries(search) : {},
    });
  }
};

export const Route = createFileRoute('/_login-layout')({
  beforeLoad: loader,
  loader,
  component: () => <Layout />,
  staticData: {
    needsAuth: false,
  },
});
