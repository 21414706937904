import React, {
  useMemo,
} from 'react';
import { RouterProvider } from '@tanstack/react-router';
import { useAuth } from '@/contexts/AuthContext.jsx';
import { useRouterHolder } from '@/contexts/RouterHolderContext.jsx';
import { useUser } from '@/contexts/UserContext.jsx';
import { LoadingScreen } from '@/contexts/LoadingScreenContext.jsx';

export default function App() {
  const user = useUser();
  const auth = useAuth();
  const router = useRouterHolder();

  const contextValues = useMemo(() => ({
    auth,
    user,
  }), [auth, user]);

  return (
    <React.Suspense fallback={<LoadingScreen text="Die CoCreation Platform wird geladen..." />}>
      <RouterProvider router={router} context={contextValues} />
    </React.Suspense>
  );
}
