import React from 'react';
import useScrollToTop from '@/components/Forms/Hooks/useScrollToTop';
import { ChevronUpIcon } from '@heroicons/react/24/solid';
import { cn } from '@/lib/utils';

export default function BackToTopButton({ className = '', local = true, topLimit = 100 }) {
  const { showBackToTop, scrollerRef, scrollToTop } = useScrollToTop(local, topLimit);

  return (
    <div
      data-state={showBackToTop ? 'open' : 'closed'}
      className={cn(
        'z-999 fixed bottom-4 right-5 md:bottom-6 md:right-12 data-[state=closed]:pointer-events-none data-[state=closed]:opacity-0 data-[state=open]:opacity-100 transition-opacity duration-300 ease-in-out',
        className,
      )}
      ref={scrollerRef}
      onClick={scrollToTop}
    >
      <div className="flex size-10 cursor-pointer items-center justify-center rounded-full bg-white md:size-12">
        <ChevronUpIcon className="size-6 text-red-500" />
      </div>
    </div>
  );
}
