import {
  useState, useEffect, useCallback,
} from 'react';

const useScrollToTop = (local = false, topLimit = 100) => {
  const [showBackToTop, setShowBackToTop] = useState(false);
  const [scrollerParent, setScrollerParent] = useState(null);

  const getGetScrollableParent = useCallback((element) => {
    if (!local || !element || !element.parentElement) {
      return window;
    }

    return element.parentElement;
  }, [local]);

  const scrollTop = useCallback(() => {
    if (!scrollerParent) return 0;
    return scrollerParent === window ? window.scrollY : scrollerParent.scrollTop;
  }, [scrollerParent]);

  const scrollerRef = useCallback((node) => {
    setScrollerParent(getGetScrollableParent(node));
  }, [getGetScrollableParent]);

  const handleScroll = useCallback(() => {
    setShowBackToTop(scrollTop() > topLimit);
  }, [scrollTop, topLimit]);

  useEffect(() => {
    if (!scrollerParent) return () => {};

    scrollerParent.addEventListener('scroll', handleScroll);
    return () => {
      scrollerParent.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll, scrollerParent]);

  const scrollToTop = useCallback(() => {
    if (!scrollerParent) return;

    scrollerParent.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  }, [scrollerParent]);

  return { showBackToTop, scrollerRef, scrollToTop };
};

export default useScrollToTop;
