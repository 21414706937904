import React, { useCallback } from 'react';
import { Button } from '@/components/ui/button.jsx';
import { useNavigate } from '@tanstack/react-router';
import PropTypes from 'prop-types';
import backgroundError from '../../../assets/shapes/error-background-shape.svg';

function ErrorPage({
  title, content, error,
}) {
  const navigate = useNavigate();
  const handleClick = useCallback(async () => {
    await navigate({ to: '/' });
  }, [navigate]);

  return (
    <div className="fixed left-0 top-0 z-[99999] h-screen w-screen bg-[#F2F2F2]">
      <div
        className="absolute right-0 top-0 hidden h-screen w-[200vw] bg-contain bg-right-bottom bg-no-repeat xl:block portrait:hidden"
        style={{
          backgroundImage: `url(${backgroundError})`,
        }}
      />
      <div className="flex h-full flex-col items-center justify-center">
        <div
          className="inset-x-8 top-[250px] mt-8 flex h-[570px] w-full flex-col items-center justify-center rounded-[30px] bg-white p-4 md:w-[527px] md:flex-row md:p-0 lg:absolute lg:left-[159px] lg:mt-0"
        >
          <div className="flex flex-col items-center justify-center">
            <div className="w-412 h-233 flex items-center justify-center">
              <p
                className="bg-gradient-to-r from-yellow-500 to-red-500 bg-clip-text !font-secondary text-[126px] font-normal leading-[176.45px] text-transparent"
              >
                {error}
              </p>
            </div>
            <div className="w-460 h-55 gap-0 ">
              <h2
                className="font-custom leading-26 text-custom text-center text-4xl font-normal"
              >
                {title}
              </h2>
            </div>
            <div className="w-460 h-55">
              <p
                className="text-custom font-custom leading-26 mx-16 my-8 text-center text-sm font-normal"
              >
                {content}
              </p>
            </div>
            <div>
              <Button
                className="w-40 min-w-fit cursor-pointer gap-1 self-end rounded-full px-8 py-2 font-semibold"
                onClick={handleClick}
              >
                Zur Startseite
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

ErrorPage.propTypes = {
  title: PropTypes.node.isRequired,
  content: PropTypes.node.isRequired,
  error: PropTypes.node.isRequired,
};

export default ErrorPage;
