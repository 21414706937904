import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';
import { useAdminMenu } from '@/contexts/AdminMenuContext.jsx';
import { useAdminMenuItem } from '@/contexts/AdminMenuItemContext.jsx';
import useRoutePath from '@/components/Hooks/useRoutePath';

export default function AdminSubMenuItem({
  className = '', icon = null, title, to, additionalRoutes = [], onClick = () => {},
}) {
  const navigate = useNavigate();
  const routePath = useRoutePath();
  const routeMatch = useMatchRoute();
  const { setActiveGroup } = useAdminMenu();
  const { title: menuItemTitle } = useAdminMenuItem();
  const [active, setActive] = useState(false);

  useEffect(() => {
    setActive(routeMatch({
      to,
      fuzzy: false,
    }) || additionalRoutes.some((route) => {
      if (route instanceof RegExp) {
        return route.test(routePath);
      }
      return routeMatch({
        to: route,
        fuzzy: false,
      });
    }));
  }, [routePath, to, additionalRoutes, routeMatch]);

  useEffect(() => {
    if (active) {
      setActiveGroup(menuItemTitle);
    }
  }, [active, menuItemTitle]);

  const handleOnClick = () => {
    navigate({
      to,
    });
    onClick();
  };

  const Icon = icon;

  return (
    <li
      role="menuitem"
      className={cn(
        'flex w-full hover:cursor-pointer bg-primary md:bg-transparent rounded-full md:rounded-none',
        className,
        active ? 'md:bg-white/20 bg-white/80 text-primary md:text-white md:border-none border border-primary' : 'md:hover:bg-white/20',
      )}
    >
      <button
        onClick={handleOnClick}
        type="button"
        className="flex w-full flex-row justify-center px-[72px] py-2 md:justify-start"
      >
        <Icon className="flex size-6 shrink-0 stroke-2" />
        <span className="ml-4 flex text-nowrap">{title}</span>
      </button>
    </li>
  );
}

AdminSubMenuItem.propTypes = {
  className: PropTypes.string,
  icon: PropTypes.any,
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  additionalRoutes: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.instanceOf(RegExp)])),
};
