import { createFileRoute } from '@tanstack/react-router';
import { mutationFn } from '@/components/Hooks/useApiMutation';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';

export const Route = createFileRoute(
  '/_admin-layout/admin/analytics-hub/user-insights_/$user/',
)({
  loader: async ({ params: { user: userId } }) => {
    const data = await mutationFn('GET', [AuthMiddleware], {
      endpoint: `/api/admin/insights/user/single-view-user/get-user?user_id=${userId}`,
    });
    return data.data;
  },
  loaderDeps: () => [Date.now()],
});
