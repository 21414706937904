import { createFileRoute } from '@tanstack/react-router';
import { mutationFn } from '@/components/Hooks/useApiMutation';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';

export const Route = createFileRoute('/_login-layout/user-invite')({
  loader: async () => {
    try {
      const { search } = window.location;
      const params = new URLSearchParams(search);
      const token = params.get('token');

      if (!token) {
        return { isValid: false, token: null, efnNeeded: null };
      }

      const data = await mutationFn('GET', [AuthMiddleware], { endpoint: `/api/auth/invite-valid/${token}` });
      return { isValid: data.success, token, efnNeeded: data.data.efn_needed };
    } catch (error) {
      return { isValid: false, token: null, efnNeeded: null };
    }
  },
});
