import React, {
  createContext, useState, useContext, useMemo,
} from 'react';
import PropTypes from 'prop-types';

const NavAdminSidebarVisibilityContext = createContext();

export function NavAdminSidebarVisibilityProvider({ children }) {
  const [sidebarVisible, setSidebarVisible] = useState(true);
  const [footerVisible, setFooterVisible] = useState(true);

  const values = useMemo(() => ({
    sidebarVisible,
    setSidebarVisible,
    footerVisible,
    setFooterVisible,
  }), [sidebarVisible, setSidebarVisible, footerVisible, setFooterVisible]);

  return (
    <NavAdminSidebarVisibilityContext.Provider value={values}>
      {children}
    </NavAdminSidebarVisibilityContext.Provider>
  );
}

NavAdminSidebarVisibilityProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
export const useNavAdminSidebarVisibility = () => useContext(NavAdminSidebarVisibilityContext);
