import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import PropTypes from 'prop-types';
import useApiMutation from '@/components/Hooks/useApiMutation';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';
import { EditableContentProvider } from '@/contexts/EditableContentContext.jsx';
import { getKeyValue } from '@/lib/utils';
import { useMatchRoute, useNavigate } from '@tanstack/react-router';
import AdboardTypes from '@/enums/AdboardTypes';
import { useToast } from '@/components/ui/use-toast';

const AdboardContext = createContext(null);

export const getAdboardDate = (adboard) => {
  const isVirtual = adboard.occurrence === AdboardTypes.VIRTUAL;
  const key = isVirtual ? 'meeting.start_time' : 'settings.adboard-onpremise-meeting.date';
  const date = getKeyValue(adboard, key, null);
  if (!date) return null;
  return new Date(date);
};

export const getAdboardStartTime = (adboard) => {
  const isVirtual = adboard.occurrence === AdboardTypes.VIRTUAL;
  const key = isVirtual ? 'meeting.start_time' : 'settings.adboard-onpremise-meeting.startTime';
  const time = getKeyValue(adboard, key, null);
  if (!time) return null;
  return new Date(time);
};

export const getAdboardEndTime = (adboard) => {
  const isVirtual = adboard.occurrence === AdboardTypes.VIRTUAL;
  const key = isVirtual ? 'meeting.end_time' : 'settings.adboard-onpremise-meeting.endTime';
  const time = getKeyValue(adboard, key, null);
  if (!time) return null;
  return new Date(time);
};

export const useAdboardProp = (adboard, key, defaultValue = null) => useMemo(() => getKeyValue(adboard, key, defaultValue), [adboard]);

export const useAdboardRedirect = (adboard, route) => {
  const navigate = useNavigate();
  const matchRoute = useMatchRoute();

  const redirectIfNecessary = useCallback(async () => {
    if (matchRoute({
      to: route,
      includeSearch: false,
      fuzzy: true,
      params: { adboard: adboard.full_slug },
    })) return;
    await navigate({
      to: route,
      params: { adboard: adboard.full_slug },
    });
  }, [adboard, matchRoute, navigate]);

  useEffect(() => {
    redirectIfNecessary();
  }, [redirectIfNecessary]);
};

export const useAdboardDate = (adboard) => useMemo(() => getAdboardDate(adboard), [adboard]);

export const useAdboardStartTime = (adboard) => useMemo(() => getAdboardStartTime(adboard), [adboard]);

export const useAdboardEndTime = (adboard) => useMemo(() => getAdboardEndTime(adboard), [adboard]);

export const useAdboardStatus = (adboard) => useMemo(() => adboard?.active === 1, [adboard]);

export default function AdboardProvider({ adboard, editable = false, children = null }) {
  const [internalAdboard, setInternalAdboard] = useState(adboard);

  const { toast } = useToast();

  const useProp = useCallback((key, defaultValue) => useAdboardProp(internalAdboard, key, defaultValue), [internalAdboard]);

  useEffect(() => {
    setInternalAdboard(adboard);
  }, [adboard]);

  const {
    mutateAsync: internalSaveAdboard,
  } = useApiMutation(
    'PUT',
    [AuthMiddleware],
    {},
    `api/admin/adboards/${adboard.id}`,
  );

  const {
    mutateAsync: internalPublishAdboard,
  } = useApiMutation(
    'POST',
    [AuthMiddleware],
    {},
    `api/admin/adboards/${adboard.id}/publish`,
  );

  const {
    mutateAsync: internalSendMessage,
  } = useApiMutation(
    'POST',
    [AuthMiddleware],
    {},
    `api/admin/adboards/${adboard.id}/message`,
  );

  const saveAdboard = useCallback(async (data) => {
    const newAdboard = {
      ...internalAdboard,
      is_latest_settings: 1,
      ...data,
    };
    const response = await internalSaveAdboard({
      body: newAdboard,
    });
    setInternalAdboard({ ...newAdboard, tasks: response.data.adboard.tasks });
  }, [internalAdboard, internalSaveAdboard]);

  const publishAdboard = useCallback(async (data) => {
    await saveAdboard({
      is_latest_settings: 0,
      ...data,
    });
    await internalPublishAdboard();
    toast({
      title: 'Adboard veröffentlicht',
      description: 'Ihr Adboard wurde erfolgreich veröffentlicht.',
      variant: 'primary',
    });
  }, [saveAdboard, internalPublishAdboard]);

  const sendMessage = useCallback(async (title, content, userIds) => {
    await internalSendMessage({
      body: {
        title, content, user_ids: userIds,
      },
    });
    toast({
      title: 'Nachricht gesendet',
      description: 'Ihre Nachricht wurde erfolgreich gesendet.',
      variant: 'default',
    });
  }, [internalSendMessage]);

  const adboardValues = useMemo(() => ({
    adboard: internalAdboard,
    useProp,
    saveAdboard,
    publishAdboard,
    sendMessage,
  }), [internalAdboard, useProp, saveAdboard, publishAdboard, sendMessage]);

  return (
    <AdboardContext.Provider value={adboardValues}>
      <EditableContentProvider enabled={editable}>
        {children}
      </EditableContentProvider>
    </AdboardContext.Provider>
  );
}

AdboardProvider.propTypes = {
  adboard: PropTypes.object.isRequired,
  editable: PropTypes.bool,
  children: PropTypes.node,
};

export const useAdboard = () => useContext(AdboardContext);
