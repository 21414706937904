import React, { useCallback, useMemo, useRef } from 'react';
import { cn } from '@/lib/utils';
import { blurhashToImageCssString } from '@unpic/placeholder';

const BlurHashImage = React.forwardRef(({
  src,
  hash = null,
  alt = '',
  className = '',
  children,
  style,
  alwaysShowBlur = false,
  ...props
}, ref) => {
  const imgRef = useRef(null);
  const blurRef = useRef(null);

  const internalStyle = useMemo(() => ({
    opacity: 0,
    transition: 'opacity 1.0s ease-in-out',
    ...style,
  }), [style]);

  const url = useMemo(() => {
    const placeholder = hash ? blurhashToImageCssString(hash) : '';
    return placeholder !== '' ? placeholder.match(/url\(([^)]+)\)/)[1].replace(/"/g, '') : '';
  }, [hash]);

  const handleOnLoad = useCallback(() => {
    if (imgRef.current) {
      imgRef.current.style.opacity = 1;
    }

    if (blurRef.current) {
      blurRef.current.style.opacity = alwaysShowBlur ? 1 : 0;
    }
  }, [imgRef, alwaysShowBlur]);

  return (
    <div ref={ref} className={cn(className, 'relative')} style={style} {...props}>
      <div
        ref={blurRef}
        style={{
          ...internalStyle,
          opacity: 1,
          backgroundImage: `url(${url})`,
          ...(!url && { background: 'linear-gradient(to right, black 0%, black 100%)' }),
        }}
        className={cn(className, 'absolute inset-0 w-full h-full bg-cover')}
      />
      {src && (
        <img
          src={src}
          alt={alt}
          style={internalStyle}
          ref={imgRef}
          className={cn(className, 'pointer-events-none absolute inset-0 w-full h-full')}
          onLoad={handleOnLoad}
        />
      )}
      {children}
    </div>
  );
});

export default BlurHashImage;
