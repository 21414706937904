import { createFileRoute, Outlet } from '@tanstack/react-router';
import NavTopbar from '@/components/Navigation/NavTopbar.jsx';
import TransitionLayout from '@/components/Animations/TransitionLayout.jsx';
import React from 'react';
import Footer from '@/components/Home/Footer.jsx';

function Layout() {
  return (
    <div className="flex flex-row font-body">
      <div className=" flex w-full min-w-0 flex-col">
        <div className="flex min-h-screen w-full min-w-0 flex-col pb-5">
          <NavTopbar inCustomerView={false} />
          <TransitionLayout className="container flex grow flex-col gap-20 px-10 py-5 font-body max-xl:px-4">
            <Outlet />
          </TransitionLayout>
        </div>
        <Footer />
      </div>
    </div>
  );
}

export const Route = createFileRoute('/_info-page-layout')({
  component: () => <Layout />,
  staticData: {
    needsAuth: false,
  },
});
