import { useState, useEffect } from 'react';

export default function useImageSize(url) {
  const [size, setSize] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const img = new Image();
    img.onload = () => {
      setSize({ width: img.width, height: img.height });
    };
    img.src = url;
  }, [url]);

  return size;
}
