import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from '@/components/ui/card.jsx';
import { Button } from '@/components/ui/button.jsx';
import { cn } from '@/lib/utils';
import {
  ArrowUturnLeftIcon,
  ChartBarSquareIcon,
  EllipsisHorizontalIcon,
  Square2StackIcon,
} from '@heroicons/react/24/outline';
import { EditIcon } from 'lucide-react';
import AdboardTypes from '@/enums/AdboardTypes';
import { useNavigate } from '@tanstack/react-router';
import Banner from '@/components/ui/banner.jsx';
import { format } from 'date-fns';
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu.jsx';
import {
  getAdboardDate,
  useAdboardDate,
  useAdboardProp,
} from '@/components/Adboards/AdboardProvider.jsx';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';
import useApiQuery from '@/components/Hooks/useApiQuery';
import {
  PlatformLayoutAdboardAdboardIndex as ViewRoute,
  AdminLayoutAdminAdboardsAdboardIdEdit as EditRoute,
  AdminLayoutAdminAnalyticsHubAdboardInsightsAdboardIndex as InsightRoute,
} from '@/routePaths.gen.js';

function AdboardSeriesDate({ adboard }) {
  const series = useMemo(
    () => adboard?.series?.filter((child, index) => index > 0) || [],
    [adboard],
  );

  const {
    data: { data: { adboards } },
  } = useApiQuery(
    '/api/adboards/bulk',
    'POST',
    [AuthMiddleware],
    {
      initialData: { data: { adboards: [] } },
      queryKey: ['/api/adboards/bulk', adboard.id],
      enabled: !!adboard && series.length > 0,
    },
    {
      body: {
        adboards: series.map((child) => child.id),
      },
    },
  );

  const nextDate = useMemo(
    () => adboards.map((child) => getAdboardDate(child))
      .filter((date) => date)
      .sort((a, b) => a - b)
      .find((date) => date > new Date()),
    [adboards],
  );

  if (!nextDate) {
    return null;
  }

  return (
    <React.Fragment>
      <p className="text-base font-medium text-primary">
        Nächster Termin:
      </p>
      <span className="text-black">{format(nextDate, 'dd.MM.yyyy')}</span>
    </React.Fragment>
  );
}

function AdboardSingleDate({ adboard }) {
  const nextDate = useAdboardDate(adboard);

  if (!nextDate) {
    return null;
  }

  return (
    <React.Fragment>
      <p className="text-base font-medium text-primary">
        Nächster Termin:
      </p>
      <span className="text-black">{format(nextDate, 'dd.MM.yyyy')}</span>
    </React.Fragment>
  );
}

export default function AdboardCard({
  adboard,
  isEditable = false,
  isArchived = false,
  dropdownContent = null,
  isInsight = null,
  isParent = true,
  className = '',
}) {
  const navigate = useNavigate();

  const isSeries = useMemo(() => adboard.type === AdboardTypes.SERIES, [adboard]);

  const openAdboard = useCallback(async () => {
    await navigate({
      to: ViewRoute,
      params: { adboard: adboard.full_slug },
    });
  }, [adboard, navigate]);

  const openAdboardEditMode = useCallback(async () => {
    await navigate({
      to: EditRoute,
      params: { adboardId: adboard.full_slug },
    });
  }, [adboard, navigate]);

  const headerUrl = useAdboardProp(adboard, 'settings.adboard-header.header.img.url', null);
  const headerObjectFit = useAdboardProp(adboard, 'settings.adboard-header.header.objectFit');
  const headerObjectPosition = useAdboardProp(adboard, 'settings.adboard-header.header.objectPosition');

  const handleAdboardInsight = useCallback(async () => {
    await navigate({
      to: InsightRoute,
      params: { adboard: adboard?.full_slug },
      replace: true,
    });
  }, [adboard, navigate]);

  return (
    <Card className={cn('flex flex-col rounded-3xl border-0', className)}>
      <CardHeader className="relative space-y-0 p-0">
        <Banner
          hash={useAdboardProp(adboard, 'settings.adboard-header.header.img.file_info.blurhash')}
          imageUrl={headerUrl}
          maxHeight={73.3333}
          minHeight={40}
          className="rounded-none rounded-t-3xl"
          style={{
            objectFit: `${headerObjectFit}`,
            objectPosition: `${headerObjectPosition}`,
            backgroundSize: `${headerObjectFit}`,
            backgroundPosition: `${headerObjectPosition}`,
          }}
        />
        {isSeries && <AdboardSeriesBadge />}
        <div className="flex px-6 py-2">
          <CardTitle className="mt-2 break-all text-[18px] font-bold">{adboard.name}</CardTitle>
          {' '}
          {' '}
          <CardDescription />
        </div>
      </CardHeader>
      <CardContent className="flex grow flex-col justify-end pb-0">
        {isSeries && (
          <p className="text-md mb-4 inline text-red-500">
            Adboard-Serie
            <Square2StackIcon className="ml-1 inline size-6 stroke-2 text-red-500" />
          </p>
        )}
        {!isSeries && (
          <AdboardSingleDate adboard={adboard} />
        )}
        {isSeries && (
          <AdboardSeriesDate adboard={adboard} />
        )}
      </CardContent>
      <CardFooter
        style={{
          flex: '0 0 85px',
        }}
      >
        {!isEditable && !isArchived && (
          isInsight ? (
            <Button
              onClick={handleAdboardInsight}
              className="self-end rounded-full px-2 font-semibold"
            >
              {isParent && (
              <ChartBarSquareIcon className="inline size-6 stroke-2" />
              )}
              {!isParent && (
              <ArrowUturnLeftIcon className="inline size-6 stroke-2" />
              )}
            </Button>
          ) : (
            <Button
              onClick={openAdboard}
              className="min-w-fit gap-1 self-end rounded-full px-8 py-2 font-semibold max-xl:w-full"
            >
              {isSeries ? 'Zur Adboard-Serie' : 'Zum Adboard'}
            </Button>
          )
        )}
        {isEditable && (
          <div className="flex flex-row gap-3 self-end">
            {!isArchived && (
              <Button
                onClick={openAdboardEditMode}
                className="relative flex aspect-square justify-center rounded-full p-0"
              >
                <EditIcon className="relative -right-px size-5 self-center stroke-2" />
              </Button>
            )}
            {!!dropdownContent && (
              <DropdownMenu>
                <DropdownMenuTrigger
                  className="relative flex aspect-square h-10 items-center justify-center whitespace-nowrap rounded-full bg-primary p-0 text-sm font-medium text-primary-foreground ring-offset-background transition-colors hover:bg-primary/90 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50"
                >
                  <EllipsisHorizontalIcon className="relative size-7 self-center stroke-2" />
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {dropdownContent}
                </DropdownMenuContent>
              </DropdownMenu>
            )}
          </div>
        )}
      </CardFooter>
    </Card>
  );
}

AdboardCard.propTypes = {
  adboard: PropTypes.object.isRequired,
  className: PropTypes.string,
  isEditable: PropTypes.bool,
  isArchived: PropTypes.bool,
  isInsight: PropTypes.bool,
  dropdownContent: PropTypes.node,
};

function AdboardSeriesBadge() {
  return (
    <div
      className="absolute right-10 flex h-12 w-9 items-center justify-center rounded-b-xl bg-red-400"
    >
      <Square2StackIcon className="relative right-[2px] ml-1 inline size-6 stroke-2 text-white" />
    </div>
  );
}
