import React, {
  createContext, useCallback, useContext, useEffect, useMemo, useState,
} from 'react';
import { deepMerge, getKeyValue, setKeyValue } from '@/lib/utils';

const LocalSettingsContext = createContext(null);

const localStorageKey = 'local-settings';

export function LocalSettingsProvider({ children, defaultSettings = {} }) {
  const [settings, setSettings] = useState(defaultSettings);

  useEffect(() => {
    const storedSettings = localStorage.getItem(localStorageKey);
    if (!storedSettings) return;
    setSettings(deepMerge(defaultSettings, JSON.parse(storedSettings)));
  }, []);

  const set = useCallback((key, value) => {
    setSettings((prev) => {
      const newSettings = setKeyValue(prev, key, value);
      localStorage.setItem(localStorageKey, JSON.stringify(newSettings));
      return newSettings;
    });
  }, []);

  const get = useCallback(
    (key, defaultValue = null) => getKeyValue(settings, key, defaultValue),
    [settings],
  );

  const has = useCallback((key) => get(key) !== undefined, [get]);

  const unset = useCallback((key) => {
    set(key, undefined);
  }, [set]);

  const values = useMemo(() => ({
    set,
    get,
    has,
    unset,
  }), [set, get, has, unset]);

  return (
    <LocalSettingsContext.Provider value={values}>
      {children}
    </LocalSettingsContext.Provider>
  );
}

export function useLocalSettings() {
  return useContext(LocalSettingsContext);
}

export function useLocalSetting(key, defaultValue = null) {
  const { get, set } = useLocalSettings();
  const value = useMemo(() => get(key, defaultValue), [get, key, defaultValue]);

  const setValue = useCallback((newValue) => {
    set(key, newValue);
  }, [key, set]);

  return [value, setValue];
}
