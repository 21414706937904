import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { EventEmitter } from 'eventemitter3';
import App from '@/components/App.jsx';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { AuthProvider } from '@/contexts/AuthContext.jsx';
import { pipe, Pipeline } from 'react-pipeline-component';
import { RouterHolderProvider } from '@/contexts/RouterHolderContext.jsx';
import { createRouter } from '@tanstack/react-router';
import { NavAdminSidebarVisibilityProvider } from '@/contexts/NavAdminSidebarVisiblityContext.jsx';
import { LoadingScreen, LoadingScreenProvider } from '@/contexts/LoadingScreenContext.jsx';
import { Toaster } from '@/components/ui/toaster.jsx';
import ErrorPage from '@/components/Error/ErrorPage.jsx';
import loadLanguages from './lang/loadLanguages';

import '@/../css/app.css';

import { LocalSettingsProvider } from '@/contexts/LocalSettingsContext.jsx';
import { UserProvider } from '@/contexts/UserContext.jsx';
import AFKProvider from '@/providers/AFKProvider.jsx';
import { TutorialProvider } from '@/providers/TutorialProvider.jsx';

// eslint-disable-next-line import/no-unresolved
import { routeTree } from '@/routeTree.gen';

(async () => {
  const queryClient = new QueryClient();

  window.emitter = new EventEmitter();
  await loadLanguages('de');

  const container = document.querySelector('#root');
  if (!container) throw new Error('Failed to find root element');

  const root = createRoot(container);
  const router = createRouter({
    routeTree,
    notFoundMode: 'root',
    defaultPendingComponent: () => <LoadingScreen text="Lädt..." />,
    defaultPreload: 'intent',
    // TODO: Add error backend logging like Sentry
    defaultErrorComponent: ({ error }) => <ErrorPage error=":(" title="Fehler" content={/* error?.message ? error.message : */ 'Ein Fehler ist aufgetreten. Bitte versuchen Sie die Seite zu aktualisieren oder kehren Sie zur Startseite zurück.'} />,
  });

  root.render(
    <StrictMode>
      <Pipeline
        components={[
          <LocalSettingsProvider
            defaultSettings={{
              devices: {
                camera: {
                  id: null,
                },
                microphone: {
                  id: null,
                  volume: 1.0,
                },
                playback: {
                  id: null,
                  volume: 1.0,
                },
              },
              tutorial: {
                finished: {},
              },
            }}
            {...pipe}
          />,
          <QueryClientProvider client={queryClient} {...pipe} />,
          <RouterHolderProvider router={router} {...pipe} />,
          <AuthProvider {...pipe} />,
          <UserProvider {...pipe} />,
          <NavAdminSidebarVisibilityProvider {...pipe} />,
          <LoadingScreenProvider {...pipe} />,
          <AFKProvider {...pipe} />,
          <TutorialProvider enabled={false} {...pipe} />, // TODO: Enable tutorial when all steps are implemented
        ]}
      >
        <App />
      </Pipeline>
      <Toaster />
    </StrictMode>,
  );
})();
