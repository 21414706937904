import { useMatches } from '@tanstack/react-router';
import { useMemo } from 'react';

export default function useStaticData() {
  const matches = useMatches();
  return useMemo(() => {
    // find the last route that has static data and return it
    let result = {};
    for (let i = matches.length - 1; i >= 0; i -= 1) {
      const match = matches[i];
      const { staticData } = match;
      // Check if there is at least one field in staticData
      if (Object.keys(staticData).length > 0) {
        result = staticData;
        break;
      }
    }
    return result;
  }, [matches]);
}
