import React, { createContext, useContext, useMemo } from 'react';

export const AdminMenuContext = createContext();

export function AdminMenuProvider({ children, setActiveGroup, activeGroup }) {
  return useMemo(
    () => (
      <AdminMenuContext.Provider value={{ setActiveGroup, activeGroup }}>
        {children}
      </AdminMenuContext.Provider>
    ),
    [setActiveGroup, activeGroup],
  );
}

export const useAdminMenu = () => useContext(AdminMenuContext);
