const EditorComponentType = Object.freeze({
  GROUP: 'Group',
  TEXT: 'Text',
  RICH_TEXT: 'RichText',
  BOOL: 'Bool',
  BOOL_SWITCH: 'BoolSwitch',
  SELECT: 'Select',
  URL: 'URL',
  TIME: 'Time',
  DATE: 'Date',
  DATE_TIME: 'DateTime',
  IMAGE: 'Image',
  USER: 'User',
  LOCATION: 'Location',
  BUTTON: 'Button',
  COMPONENT_DISPLAY: 'ComponentDisplay',
});

export default EditorComponentType;
