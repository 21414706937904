import { createFileRoute, useNavigate } from '@tanstack/react-router';
import useApiQuery from '@/components/Hooks/useApiQuery.js';
import AuthMiddleware from '@/Middlewares/AuthMiddleware.js';
import React, { useCallback, useMemo } from 'react';
import {
  AdminLayoutAdminAnalyticsHubAdboardInsightsIndex as AdboardRoute,
} from '@/routePaths.gen.js';
import { Button } from '@/components/ui/button.jsx';
import { Squares2X2Icon } from '@heroicons/react/24/outline/index.js';
import AdboardsContainer from '@/components/Home/AdboardsContainer.jsx';
import AdboardCard from '@/components/Home/AdboardCard.jsx';
import Spinner from '@/components/ui/spinner.jsx';

function ArchivedAdboards() {
  const navigate = useNavigate();

  const {
    data: {
      data: { adboards = [] },
    },
    isFetching,
  } = useApiQuery('/api/admin/adboards/list/' + 'archived', 'GET', [AuthMiddleware], {
    initialData: {
      data: {
        adboards: {
          archived: [],
        },
      },
    },
    enabled: true,
  });

  const handleAdboards = useCallback(() => {
    navigate({
      to: AdboardRoute,
    });
  }, [navigate]);

  const archiveAdboards = useMemo(() => adboards?.archived, [adboards]);

  return (
    <React.Fragment>
      <div className="mt-2 flex flex-col items-center gap-3 md:flex-row md:justify-between md:gap-0">
        <h1 className="flex self-center text-center text-2xl font-bold text-primary md:text-left">
          Adboard Insights
        </h1>
        <div className="mr-10 flex justify-end gap-5">
          <Button
            onClick={handleAdboards}
            className="rounded-full border border-primary bg-white px-6 text-[14px] font-bold text-primary hover:bg-white"
          >
            Aktive Adboards
            <Squares2X2Icon className="ml-2 size-5" />
          </Button>
        </div>
      </div>

      <AdboardsContainer
        title="Archivierte Adboards"
        description="Archivierte Adboards werden nicht mehr auf der Startseite der Teilnehmer*innen angezeigt."
        className="gap-7"
      >
        {!isFetching && archiveAdboards.map((adboard) => (
          <AdboardCard
            key={adboard.id}
            adboard={adboard}
            className="max-w-[320px]"
            isInsight
          />
        ))}
      </AdboardsContainer>
      {isFetching && (
        <div className="relative w-full">
          <Spinner
            width="70px"
            height="70px"
            className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2 text-primary"
          />
        </div>
      )}
    </React.Fragment>
  );
}

export const Route = createFileRoute('/_admin-layout/admin/analytics-hub/adboard-insights/archive')({
  component: () => <ArchivedAdboards />,
});
