import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useLocalSetting } from '@/contexts/LocalSettingsContext.jsx';
import { Button } from '@/components/ui/button.jsx';
import { Popover, PopoverContent, PopoverTrigger } from '@/components/ui/popover.jsx';
import useElementRect from '@/components/Hooks/useElementRect';

const offset = 6;

const TutorialContext = createContext(null);

function Spotlight({ anchorEl }) {
  const [rect, setRef] = useElementRect();

  useEffect(() => {
    setRef(anchorEl);
  }, [anchorEl, setRef]);

  return anchorEl != null ? (
    <React.Fragment>
      <div
        className="fixed rounded-md shadow-[0_0_0_9999px_rgba(0,0,0,0.5)]"
        style={{
          top: rect.top - offset,
          left: rect.left - offset,
          width: rect.width + offset * 2,
          height: rect.height + offset * 2,
        }}
      />
      <div
        className="fixed rounded-md"
        style={{
          top: rect.top - offset,
          left: rect.left - offset,
          width: rect.width + offset * 2,
          height: rect.height + offset * 2,
        }}
      />
    </React.Fragment>
  ) : (
    <div
      className="fixed inset-0 bg-black/50"
    />
  );
}

function TutorialPopper({ anchorEl, children }) {
  const [rect, setRef] = useElementRect();

  useEffect(() => {
    setRef(anchorEl);
  }, [anchorEl, setRef]);

  return (
    <Popover
      open
    >
      <PopoverTrigger asChild>
        {anchorEl ? (
          <div
            className="pointer-events-none fixed"
            style={{
              top: rect.top - offset,
              left: rect.left - offset,
              width: rect.width + offset * 2,
              height: rect.height + offset * 2,
            }}
          />
        ) : (
          <div
            className="pointer-events-none fixed left-1/2 top-1/2"
          />
        )}
      </PopoverTrigger>
      <PopoverContent
        className="z-[110] flex flex-col gap-6 overflow-hidden rounded-xl border-none p-0"
      >
        {children}
      </PopoverContent>
    </Popover>
  );
}

export function TutorialProvider({ enabled = true, children }) {
  const [tutorialSteps, setTutorialSteps] = useState([]);

  const [finishedTutorials, setFinishedTutorials] = useLocalSetting('tutorial.finished', []);

  const registerStep = useCallback((node, title, message, key, priority) => {
    const stepId = Math.random().toString(36).substring(7);
    setTutorialSteps((prev) => {
      const newSteps = [...prev];
      newSteps.push({
        id: stepId,
        node,
        title,
        message,
        key,
        priority: priority || 0,
        read: false,
      });
      return newSteps;
    });
    return stepId;
  }, []);

  const unregisterStep = useCallback((id) => {
    setTutorialSteps((prev) => [...prev].filter((s) => s.id !== id));
  }, []);

  const readStep = useCallback((id) => {
    setTutorialSteps((prev) => {
      const newSteps = [...prev];
      const step = newSteps.find((s) => s.id === id);
      if (!step) return newSteps;
      step.read = true;

      const settingsObject = finishedTutorials || {};
      settingsObject[step.key] = true;
      setFinishedTutorials({ ...settingsObject });

      return newSteps;
    });
  }, [finishedTutorials, setFinishedTutorials]);

  const readAllSteps = useCallback(() => {
    setTutorialSteps((prev) => {
      const newSteps = [...prev];
      const settingsObject = finishedTutorials || {};
      newSteps.forEach((step) => {
        step.read = true;
        settingsObject[step.key] = true;
      });
      setFinishedTutorials({ ...settingsObject });
      return newSteps;
    });
  }, [finishedTutorials, setFinishedTutorials]);

  const unreadAllSteps = useCallback(() => {
    setTutorialSteps((prev) => {
      const newSteps = [...prev];
      const settingsObject = finishedTutorials || {};
      newSteps.forEach((step) => {
        step.read = false;
        settingsObject[step.key] = false;
      });
      setFinishedTutorials({ ...settingsObject });
      return newSteps;
    });
  }, [finishedTutorials, setFinishedTutorials]);

  const firstUnreadStep = useMemo(
    () => tutorialSteps.sort((a, b) => a.priority - b.priority).find((s) => !s.read && !finishedTutorials[s.key]),
    [tutorialSteps, finishedTutorials],
  );

  const tutorialValues = useMemo(() => ({
    registerStep,
    unregisterStep,
    readStep,
    unreadAllSteps,
  }), [registerStep, unregisterStep, readStep, unreadAllSteps]);

  return (
    <TutorialContext.Provider value={tutorialValues}>
      {children}
      {(enabled && firstUnreadStep) && (
      <div
        className="fixed inset-0 z-[100]"
      >
        <Spotlight
          anchorEl={firstUnreadStep.node}
        />
        <TutorialPopper
          anchorEl={firstUnreadStep.node}
        >
          <div
            className="flex flex-col justify-center bg-gradient-to-r from-[#FF585D] from-15% to-[#FFC100] to-[87%] px-4 py-2 font-body text-xl font-semibold text-white"
          >
            {firstUnreadStep.title}
          </div>
          <div
            className="text-md px-4 font-semibold text-primary"
          >
            {firstUnreadStep.message}
          </div>
          <div
            className="flex justify-center gap-2 p-2 pb-6"
          >
            <Button
              type="button"
              onClick={() => readAllSteps()}
              size="small"
              className="rounded-full border border-black bg-white px-5 text-sm font-semibold text-black hover:bg-white/90"
            >
              Überspringen
            </Button>
            <Button
              type="button"
              onClick={() => readStep(firstUnreadStep.id)}
              size="small"
              className="rounded-full px-8 py-2 text-sm font-semibold"
            >
              Weiter
            </Button>
          </div>
        </TutorialPopper>
      </div>
      )}
    </TutorialContext.Provider>
  );
}

export const useTutorial = () => useContext(TutorialContext);

export const useTutorialStep = (title, message, key, priority) => {
  const [node, setNode] = useState(null);

  const { registerStep, unregisterStep } = useTutorial();

  const setRef = useCallback((ref) => {
    setNode(ref);
  }, []);

  useEffect(() => {
    if (!node) return () => {};
    const id = registerStep(node, title, message, key, priority);
    return () => {
      unregisterStep(id);
    };
  }, [node, title, message, key, priority, registerStep, unregisterStep]);

  return [setRef];
};

export const useTutorialInfo = (title, message, key, priority) => {
  const { registerStep, unregisterStep } = useTutorial();

  useEffect(() => {
    const id = registerStep(null, title, message, key, priority);
    return () => {
      unregisterStep(id);
    };
  }, [title, message, key, priority, registerStep, unregisterStep]);
};
