import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';

const useElementRect = () => {
  const [rect, setRect] = useState({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  });
  const [element, setElement] = useState(null);

  const elementRef = useCallback((node) => {
    setElement(node);
  }, []);

  const observerRef = useRef(null);

  const updateRect = useCallback(() => {
    if (!element || !element.getBoundingClientRect) return;
    const {
      top, left, width, height,
    } = element.getBoundingClientRect();
    setRect({
      top,
      left,
      width,
      height,
    });
  }, [element]);

  useEffect(() => {
    updateRect();
  }, [element?.offsetHeight, element?.offsetWidth, updateRect]);

  useEffect(() => {
    if (!element) return () => {};

    observerRef.current = new ResizeObserver(() => {
      updateRect();
    });
    observerRef.current.observe(element);

    return () => {
      observerRef.current.disconnect();
    };
  }, [element, updateRect]);

  return [rect, elementRef];
};

export default useElementRect;
