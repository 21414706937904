import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';
import { Button } from './button';

const IconButton = React.forwardRef(({ icon, className = '', ...props }, ref) => (
  <Button
    ref={ref}
    className={cn(
      'bg-transparent text-black hover:bg-[#ffffff33]',
      className,
    )}
    {...props}
  >
    {icon}
  </Button>
));
IconButton.displayName = 'IconButton';

IconButton.propTypes = {
  icon: PropTypes.node.isRequired,
  className: PropTypes.string,
};

export default IconButton;
