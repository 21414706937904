import {
  createFileRoute, Link, Outlet, redirect,
} from '@tanstack/react-router';
import React, { Suspense } from 'react';
import TransitionLayout from '@/components/Animations/TransitionLayout.jsx';
import { FooterLinks } from '@/components/Home/Footer.jsx';

// eslint-disable-next-line import/no-unresolved
import LoginBackgroundShapeImage from '../../assets/shapes/login-background-shape.png?format=webp';
import NovartisLogoImage from '../../assets/logos/novartis-logo-rgb.svg';

function Layout() {
  return (
    <Suspense fallback={null}>
      <TransitionLayout className="container flex h-screen p-10 font-body max-xl:px-4">
        <img
          src={NovartisLogoImage}
          alt="Novartis Logo"
          className="absolute top-5 h-4 min-h-14 md:top-auto md:h-16 lg:block"
        />
        <div className="ml-36 flex flex-col justify-center max-xl:mx-auto">
          <div className="mt-auto flex max-w-[465px] flex-col">
            <Outlet />
          </div>
          <div className="mt-auto flex flex-col text-center md:flex-row md:gap-5">
            <FooterLinks className="text-black" />
          </div>
        </div>
        <div
          className="pointer-events-none absolute right-0 top-0 hidden h-screen w-[200vw] xl:block portrait:hidden"
          style={{
            backgroundImage: `url(${LoginBackgroundShapeImage})`,
            backgroundRepeat: 'no-repeat',
            backgroundPosition: 'right bottom',
            backgroundSize: 'contain',
          }}
        />
      </TransitionLayout>
    </Suspense>
  );
}

export const Route = createFileRoute('/_2fa-layout')({

  loader: ({ context }) => context?.user.ready().catch(() => {
    context.auth.logout();
  }),
  beforeLoad: async ({ context }) => {
    await context.user.ready().catch(() => {
      context.auth.logout();
    });
  },
  component: () => <Layout />,
  staticData: {
    needsAuth: true,
  },
});
