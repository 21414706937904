import { useUser } from '@/contexts/UserContext.jsx';
import { useMemo } from 'react';

export default function useRole(roles) {
  const { hasRole } = useUser();

  const result = useMemo(() => {
    if (Array.isArray(roles)) {
      return roles.map((role) => hasRole(role));
    }
    return hasRole(roles);
  }, [roles, hasRole]);

  const total = useMemo(() => {
    if (Array.isArray(result)) {
      return result.every((role) => role);
    }
    return result;
  }, [result]);

  return [result, total];
}
