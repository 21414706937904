import { createFileRoute } from '@tanstack/react-router';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';
import { mutationFn } from '@/components/Hooks/useApiMutation';

export const Route = createFileRoute('/_platform-layout/feedback_')({
  loader: async ({ context }) => {
    const data = await mutationFn(
      'GET',
      [AuthMiddleware],
      { endpoint: '/api/forum/feedback' },
      context,
    );
    return data.data.forum;
  },
  loaderDeps: () => [Date.now()], // This is a workaround to force the loader to run every time the route is navigated to
});
