import { createFileRoute } from '@tanstack/react-router';
import { mutationFn } from '@/components/Hooks/useApiMutation';
import AuthMiddleware from '@/Middlewares/AuthMiddleware';

export const Route = createFileRoute(
  '/_platform-layout/adboard/$adboard/forum_',
)({
  loader: async ({ params: { adboard: adboardId }, context }) => {
    const id = adboardId.split('-').pop();
    const data = await mutationFn(
      'GET',
      [AuthMiddleware],
      { endpoint: `/api/adboards/${id}` },
      context,
    );
    return data.data.adboard;
  },
});
