import React from 'react';
import PropTypes from 'prop-types';
import { cn } from '@/lib/utils';

export default function AdboardsContainer({
  children = null,
  className = '',
  title,
  subtitle,
  description = null,
}) {
  return (
    <div className={cn('flex flex-col', className)}>
      <h3 className="text-center text-2xl font-bold text-primary md:text-left">
        {title}
      </h3>
      {subtitle && (
        <h3 className="mb-3 text-center text-xl font-medium text-primary md:text-left">
          {subtitle}
        </h3>
      )}
      {description && (
        <p className="mb-3 text-center text-base text-primary md:text-left">
          {description}
        </p>
      )}
      <div className="grid grid-cols-[repeat(auto-fill,min(320px,100%))] justify-center gap-8 md:grid-cols-[repeat(auto-fill,minmax(min(320px,100%),1fr))]">
        {children}
      </div>
    </div>
  );
}

AdboardsContainer.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
};
