import { HttpStatusCode } from 'axios';
import { toast } from '@/components/ui/use-toast';

// TODO: Remove me
const AuthMiddleware = {
  request: (request) => {
    if (!localStorage.getItem('token')) {
      return request;
    }
    request.headers = {
      ...request.headers,
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    };

    return request;
  },

  response: async (response) => {
    if (response.status === HttpStatusCode.Unauthorized) {
      const newResponse = response.clone();
      const errorData = await newResponse.json();
      const { message, error } = errorData;

      if (message === 'Session Terminated') {
        toast({
          title: 'Sie sind von der Anwendung abgemeldet worden',
          description: 'Ein anderer Benutzer hat auf das Konto zugegriffen.',
          variant: 'destructive',
        });
      }
    }

    const newToken = response.headers.get('X-New-Token');
    if (newToken) {
      localStorage.setItem('token', newToken);
    }
  },
};

export default AuthMiddleware;
