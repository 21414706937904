import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Squares2X2Icon,
  IdentificationIcon,
  SparklesIcon,
  SwatchIcon,
  UserGroupIcon,
  KeyIcon,
  LifebuoyIcon,
  ChartBarSquareIcon,
  PuzzlePieceIcon,
  UsersIcon,
  HomeIcon,
  Square3Stack3DIcon,
  ChatBubbleLeftRightIcon, SignalIcon, FolderOpenIcon,
  Bars3Icon, XMarkIcon,
} from '@heroicons/react/24/outline';
import { cn } from '@/lib/utils';
import AdminMenuItemGroup from '@/components/Navigation/Admin/AdminMenuItemGroup.jsx';
import AdminSubMenuItem from '@/components/Navigation/Admin/AdminSubMenuItem.jsx';
import AdminSubMenu from '@/components/Navigation/Admin/AdminSubMenu.jsx';
import AdminMenuItem from '@/components/Navigation/Admin/AdminMenuItem.jsx';
import AdminMenu from '@/components/Navigation/Admin/AdminMenu.jsx';
import { AdminMenuProvider } from '@/contexts/AdminMenuContext.jsx';
import usePermission from '@/components/Hooks/usePermission';
import {
  AdminLayoutAdminUserHubUsers as UserAdminRoute,
  AdminLayoutAdminUserHubRoles as RolesAdminRoute,
  AdminLayoutAdminConferenceHubMyAdboardsIndex as MyAdboardsRoute,
  AdminLayoutAdminConferenceHubMyAdboardsDrafts as MyAdboardsDraftsRoute,
  AdminLayoutAdminConferenceHubMyAdboardsArchive as MyAdboardsArchiveRoute,
  AdminLayoutAdminOperationsHubAseReportingIndex as AseReportingRoute,
  AdminLayoutAdminUserHubIndex as UserHubRoute,
  AdminLayoutAdminConferenceHubIndex as ConferenceHubRoute,
  AdminLayoutAdminOperationsHubIndex as OperationsHubRoute,
  AdminLayoutAdminAnalyticsHubUserInsights as UserInsightsHubRoute,
  AdminLayoutAdminAnalyticsHubPlatformInsightsIndex as PlatformInsightsHubRoute,
  AdminLayoutAdminAnalyticsHubAdboardInsightsIndex as AdboardInsightsHubRoute,
  AdminLayoutAdminAnalyticsHubIndex as AnalyticsHubRoute,
  AdminLayoutAdminFaqHubFaq as FaqHubRoute,
  AdminLayoutAdminFaqHubCategory as CategoryHubRoute,
  AdminLayoutAdminOperationsHubPulseIndex,
  AdminLayoutAdminConferenceHubContentManagerIndex as ContentManagerRoute,
  AdminLayoutAdminConferenceHubContentManagerFolder as ContentManagerFolderRoute,
  AdminLayoutAdminAnalyticsHubFeedbackInsightsIndex as FeedbackInsightsHubRoute,
} from '@/routePaths.gen.js';
import useRole from '@/components/Hooks/useRole';
import IconButton from '@/components/ui/icon-button.jsx';
import FeedbackIcon from '@/components/Icons/Outlines/FeedbackIcon.jsx';

export default function NavAdminSidebar({ className = '' }) {
  const [userViewPermissions] = usePermission('users.view');
  const [rolesViewPermissions] = usePermission('roles.view');
  const [adboardsEditPermissions] = usePermission('adboards.edit');
  const [isAdmin] = useRole('admin');
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const [activeGroup, setActiveGroup] = useState(null);
  const toggleMobileMenu = useCallback(() => {
    setIsMobileMenuOpen((prev) => !prev);
  }, []);
  return (
    <React.Fragment>
      <IconButton
        onClick={toggleMobileMenu}
        className="fixed left-5 top-[12px] z-50 bg-primary text-white hover:bg-primary/95 md:hidden"
        icon={isMobileMenuOpen ? (
          <XMarkIcon className="size-6 rotate-90 stroke-2" />
        ) : (
          <Bars3Icon className="size-6 rotate-0 stroke-2" />
        )}
      />
      <nav
        data-open={isMobileMenuOpen}
        className={cn('data-[open="true"]:flex data-[open="false"]:hidden md:data-[open]:flex gap-4 md:gap-0 w-full '
          + 'bg-[#f2f2f4] z-20 fixed text-white md:text-black md:sticky left-0 top-[64px] md:top-0 h-screen '
          + 'md:w-80 md:min-w-64 lg:min-w-80 md:max-w-[320px] flex-col items-start justify-start md:justify-between md:bg-gradient-to-t '
          + 'md:from-[#FF585D] md:from-[-9%] md:to-[#FFC100] md:to-[106%] flex', className)}
      >
        <AdminMenuProvider
          activeGroup={activeGroup}
          setActiveGroup={setActiveGroup}
        >
          <AdminMenu>
            {(userViewPermissions || rolesViewPermissions) && (
            <AdminMenuItem>
              <AdminMenuItemGroup
                icon={UserGroupIcon}
                title="User Hub"
                route={UserHubRoute}
                setActive={setActiveGroup}
                active={activeGroup === 'User Hub'}
              >
                <AdminSubMenu>
                  {userViewPermissions && (
                    <AdminSubMenuItem
                      icon={IdentificationIcon}
                      title="User Verwaltung"
                      to={UserAdminRoute}
                      onClick={toggleMobileMenu}
                    />
                  )}
                  {rolesViewPermissions && (
                    <AdminSubMenuItem
                      icon={SwatchIcon}
                      title="Rollen Verwaltung"
                      to={RolesAdminRoute}
                      onClick={toggleMobileMenu}
                    />
                  )}
                </AdminSubMenu>
              </AdminMenuItemGroup>
            </AdminMenuItem>
            )}
            {adboardsEditPermissions && (
            <AdminMenuItem>
              <AdminMenuItemGroup
                icon={SparklesIcon}
                title="Conference Hub"
                route={ConferenceHubRoute}
                setActive={setActiveGroup}
                active={activeGroup === 'Conference Hub'}
              >
                <AdminSubMenu>
                  <AdminSubMenuItem
                    icon={Squares2X2Icon}
                    title="Meine Adboards"
                    to={MyAdboardsRoute}
                    additionalRoutes={[
                      MyAdboardsDraftsRoute,
                      MyAdboardsArchiveRoute,
                    ]}
                    onClick={toggleMobileMenu}
                  />
                  <AdminSubMenuItem
                    icon={FolderOpenIcon}
                    title="Content Manager"
                    to={ContentManagerRoute}
                    additionalRoutes={[
                      ContentManagerFolderRoute,
                    ]}
                    onClick={toggleMobileMenu}
                  />
                </AdminSubMenu>
              </AdminMenuItemGroup>
            </AdminMenuItem>
            )}
            {adboardsEditPermissions && (
            <AdminMenuItem>
              <AdminMenuItemGroup
                icon={ChartBarSquareIcon}
                title="Analytics Hub"
                route={AnalyticsHubRoute}
                setActive={setActiveGroup}
                active={activeGroup === 'Analytics Hub'}
              >
                <AdminSubMenu>
                  <AdminSubMenuItem
                    icon={PuzzlePieceIcon}
                    title="Adboard Insights"
                    to={AdboardInsightsHubRoute}
                    onClick={toggleMobileMenu}
                  />
                  <AdminSubMenuItem
                    icon={HomeIcon}
                    title="Platform Insights"
                    to={PlatformInsightsHubRoute}
                    onClick={toggleMobileMenu}
                  />

                  <AdminSubMenuItem
                    icon={UsersIcon}
                    title="User Insights"
                    to={UserInsightsHubRoute}
                    onClick={toggleMobileMenu}
                  />
                  <AdminSubMenuItem
                    icon={() => FeedbackIcon({ className: 'shrink-0 !stroke-[3px]' })}
                    title="Feedback Insights"
                    to={FeedbackInsightsHubRoute}
                    onClick={toggleMobileMenu}
                  />
                </AdminSubMenu>
              </AdminMenuItemGroup>
            </AdminMenuItem>
            )}
            {isAdmin && (
            <AdminMenuItem>
              <AdminMenuItemGroup
                icon={KeyIcon}
                title="Operations Hub"
                route={OperationsHubRoute}
                setActive={setActiveGroup}
                active={activeGroup === 'Operations Hub'}
              >
                <AdminSubMenu>
                  <AdminSubMenuItem
                    icon={LifebuoyIcon}
                    title="ASE Monitoring"
                    to={AseReportingRoute}
                    onClick={toggleMobileMenu}
                  />
                </AdminSubMenu>
                <AdminSubMenu>
                  <AdminSubMenuItem
                    icon={SignalIcon}
                    title="Pulse"
                    to={AdminLayoutAdminOperationsHubPulseIndex}
                    onClick={toggleMobileMenu}
                  />
                </AdminSubMenu>
                <AdminSubMenu>
                  <AdminSubMenuItem
                    icon={ChatBubbleLeftRightIcon}
                    title="FAQ Verwaltung"
                    to={FaqHubRoute}
                    onClick={toggleMobileMenu}
                  />
                </AdminSubMenu>
                <AdminSubMenu>
                  <AdminSubMenuItem
                    icon={Square3Stack3DIcon}
                    title="Kategorie Verwaltung"
                    to={CategoryHubRoute}
                    onClick={toggleMobileMenu}
                  />
                </AdminSubMenu>
              </AdminMenuItemGroup>
            </AdminMenuItem>
            )}
          </AdminMenu>
        </AdminMenuProvider>
      </nav>
    </React.Fragment>
  );
}

NavAdminSidebar.propTypes = {
  className: PropTypes.string,
};
