import { createFileRoute } from '@tanstack/react-router';
import React from 'react';
import WelcomeBanner from '@/components/Home/WelcomeBanner.jsx';
import {
  FolderOpenIcon, Squares2X2Icon,
} from '@heroicons/react/24/outline';
import {
  AdminLayoutAdminConferenceHubContentManagerIndex as ContentManagerRoute,
  AdminLayoutAdminConferenceHubMyAdboardsIndex as MyAdboardsHubRoute,
} from '@/routePaths.gen.js';
import HubCard from '@/components/Admin/HubCard.jsx';
// eslint-disable-next-line import/no-unresolved
import HomeScreenBannerImage from '@/../assets/banners/home-screen-banner.jpg?format=webp';

function Index() {
  return (
    <React.Fragment>
      <WelcomeBanner imageUrl={HomeScreenBannerImage} hash="L9KK=_000000%K_1-:-o_NjDI9.8" />
      <h3 className="text-center text-2xl font-bold text-primary md:text-left">
        Was möchtest Du tun?
      </h3>
      <div className="flex flex-col gap-8 px-4 sm:flex-row">
        <HubCard
          title="Meine Adboards"
          icon={Squares2X2Icon}
          className="w-full md:w-72"
          link={MyAdboardsHubRoute}
        />
        <HubCard
          title="Content Manager"
          icon={FolderOpenIcon}
          className="w-full md:w-72"
          link={ContentManagerRoute}
        />
      </div>
    </React.Fragment>
  );
}

export const Route = createFileRoute('/_admin-layout/admin/conference-hub/')({
  component: () => <Index />,
});
