import React from 'react';
import { cn } from '@/lib/utils';

export default function FeedbackIcon({ className, ...props }) {
  return (
    <svg
      viewBox="0 0 49 40"
      fill="none"
      strokeWidth="1.5"
      stroke="currentColor"
      className={cn('size-6', className)}
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M41.7072 4.16667L37.7906 3.1875C37.6254 3.14609 37.4557 3.1251 37.2854 3.125H33.0999C32.713 3.12501 32.3337 3.23281 32.0045 3.43629C31.6754 3.63978 31.4094 3.93092 31.2364 4.27708L27.5906 11.5687C27.4319 11.8864 27.357 12.2393 27.373 12.594C27.389 12.9487 27.4954 13.2934 27.6821 13.5955C27.8688 13.8975 28.1296 14.1468 28.4397 14.3197C28.7499 14.4926 29.099 14.5833 29.4541 14.5833H34.4166H36.4999M41.7072 4.16667L41.7083 13.5417L38.1749 18.8417C37.7614 19.4615 37.5416 20.1885 37.5416 20.9333C37.5416 21.4531 37.1197 21.875 36.5989 21.875H36.4989C35.9464 21.875 35.4165 21.6555 35.0258 21.2648C34.6351 20.8741 34.4156 20.3442 34.4156 19.7917V14.5833M41.7072 4.16667H43.7916C44.3441 4.16667 44.8741 4.38616 45.2648 4.77686C45.6555 5.16756 45.8749 5.69747 45.8749 6.25V12.5C45.8749 13.0525 45.6555 13.5824 45.2648 13.9731C44.8741 14.3638 44.3441 14.5833 43.7916 14.5833H41.1874"
      />
      <path
        d="M20.4167 19.5833H27.3642C27.8613 19.5834 28.3501 19.7104 28.7843 19.9525C29.2185 20.1945 29.5836 20.5435 29.845 20.9664C30.1064 21.3892 30.2553 21.8718 30.2778 22.3684C30.3002 22.865 30.1953 23.3591 29.9731 23.8038L24.869 34.0121C24.6267 34.4969 24.2541 34.9047 23.793 35.1896C23.3319 35.4745 22.8005 35.6253 22.2585 35.625H16.4004C16.1627 35.625 15.925 35.5958 15.6931 35.5375L10.2083 34.1667M20.4167 19.5833V12.2917C20.4167 11.5181 20.1094 10.7763 19.5624 10.2293C19.0154 9.68229 18.2735 9.375 17.5 9.375H17.3615C16.6323 9.375 16.0417 9.96562 16.0417 10.6948C16.0417 11.736 15.734 12.754 15.155 13.6202L10.2083 21.0417V34.1667M20.4167 19.5833H17.5M10.2083 34.1667H7.29167C6.51812 34.1667 5.77625 33.8594 5.22927 33.3124C4.68229 32.7654 4.375 32.0235 4.375 31.25V22.5C4.375 21.7265 4.68229 20.9846 5.22927 20.4376C5.77625 19.8906 6.51812 19.5833 7.29167 19.5833H10.9375"
      />
    </svg>
  );
}
