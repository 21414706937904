import { useEffect, useState } from 'react';
import useImageSize from '@/components/Hooks/useImageSize';
import { useElementSize } from 'usehooks-ts';

const useBannerSize = (image, maxHeight, minHeight) => {
  const imageSize = useImageSize(image);
  const [elementRef, { width, height }] = useElementSize();
  const [bannerHeight, setBannerHeight] = useState(maxHeight);

  useEffect(() => {
    const ratio = imageSize.width / imageSize.height;
    const newHeight = width / ratio;
    if (newHeight > maxHeight) {
      setBannerHeight(maxHeight);
    } else if (newHeight < minHeight) {
      setBannerHeight(minHeight);
    } else {
      setBannerHeight(Math.fround(newHeight));
    }
  }, [imageSize, width, height, maxHeight]);

  return [elementRef, bannerHeight];
};

export default useBannerSize;
