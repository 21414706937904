import React, { createContext, useContext } from 'react';

export const RouterHolderContext = createContext();

export function RouterHolderProvider({ children, router }) {
  return (
    <RouterHolderContext.Provider value={router}>
      {children}
    </RouterHolderContext.Provider>
  );
}

export const useRouterHolder = () => useContext(RouterHolderContext);
