import PropTypes from 'prop-types';
import React, { createContext, useContext } from 'react';

export const AdminMenuItemContext = createContext();

export function AdminMenuItemProvider({ children, title }) {
  return (
    <AdminMenuItemContext.Provider value={{ title }}>
      {children}
    </AdminMenuItemContext.Provider>
  );
}

AdminMenuItemProvider.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export const useAdminMenuItem = () => useContext(AdminMenuItemContext);
