import useLazyQuery from '@/components/Hooks/useLazyQuery';
import { mutationFn } from '@/components/Hooks/useApiMutation';
import { useRouteContext } from '@tanstack/react-router';

const useApiQuery = (
  endpoint,
  method = 'GET',
  middlewares = [],
  queryOpts = {},
  params = {},
) => {
  const context = useRouteContext({});
  return useLazyQuery({
    queryKey: [endpoint, method],
    queryFn: async () => mutationFn(method, middlewares, { ...params, endpoint }, context),
    ...queryOpts,
  });
};
export default useApiQuery;
