import React from 'react';
import { createFileRoute, Outlet, redirect } from '@tanstack/react-router';
import NavSidebar from '@/components/Navigation/NavSidebar.jsx';
import NavTopbar from '@/components/Navigation/NavTopbar.jsx';
import TransitionLayout from '@/components/Animations/TransitionLayout.jsx';
import Footer from '@/components/Home/Footer.jsx';

function Layout() {
  return (
    <div className="flex flex-row font-body">
      <NavSidebar />
      <div className=" flex w-full min-w-0 flex-col">
        <div className="flex min-h-screen w-full min-w-0 flex-col pb-5">
          <NavTopbar inCustomerView />
          <TransitionLayout className="container flex grow flex-col gap-20 px-10 py-5 font-body max-xl:px-4">
            <Outlet />
          </TransitionLayout>
        </div>
        <Footer />
      </div>
    </div>
  );
}

// eslint-disable-next-line import/prefer-default-export
export const Route = createFileRoute('/_platform-layout')({
  loader: async ({ context, location }) => {
    await context?.user.ready().catch(() => {});

    if (!context.auth.isAuthenticated()) {
      throw redirect({
        to: '/login',
        search: {
          redirect: location.href,
        },
      });
    }

    const search = new URLSearchParams(window.location.search);
    const redirectTo = search.get('redirect');
    if (redirectTo) {
      return redirect({
        to: redirectTo,
      });
    }

    return context.user.ready().catch(() => {
      context.auth.logout();
    });
  },
  beforeLoad: async ({ context }) => context.user.ready().catch(() => {
    context.auth.logout();
  }),
  component: () => <Layout />,
  loaderDeps: ({ search }) => [search],
  staticData: {
    needsAuth: true,
  },
});
