import React from 'react';
import { cn } from '@/lib/utils.js';
import {
  Card, CardContent, CardHeader, CardTitle,
} from '@/components/ui/card.jsx';
import PropTypes from 'prop-types';
import { Link } from '@tanstack/react-router';

 
function HubCard({
  title = '', subtitle = [], icon = null, link = null, className = '',
}) {
  const Icon = icon;
  return (
    <Card className={cn('flex flex-col rounded-3xl border-0 py-10 gap-4', className)}>
      <CardHeader className="relative space-y-0 p-0">
        <Icon className="mx-auto flex size-12 stroke-1 text-primary" />
      </CardHeader>
      <CardContent className="flex grow flex-col justify-end gap-8 pb-2">
        <CardTitle className="text-center text-2xl font-bold text-primary">
          {link ? (
            <Link to={link} className="text-primary hover:text-primary/80 hover:underline">
              {title}
            </Link>
          ) : title}
        </CardTitle>
        {!!subtitle.length && (
          <div className="flex flex-1 flex-col gap-1 text-center text-base text-primary">
            {subtitle.map((sub) => (
              <Link
                key={sub.link}
                to={sub.link}
                className="text-sm font-light text-primary hover:text-primary/80 hover:underline"
              >
                {sub.name}
              </Link>
            ))}
          </div>
        )}
      </CardContent>
    </Card>
  );
}

export default HubCard;

HubCard.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.array,
  icon: PropTypes.any,
  link: PropTypes.string,
  className: PropTypes.string,
};
