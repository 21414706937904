import { createRootRoute, Outlet } from '@tanstack/react-router';
import React from 'react';
import { AnimatePresence } from 'framer-motion';
import { TanStackRouterDevtools } from '@tanstack/router-devtools';
import BackToTopButton from '@/components/Buttons/BackToTopButton.jsx';
import CustomScrollbar from '@/components/Scrollbar/CustomScrollbar.jsx';
import ErrorPage from '@/components/Error/ErrorPage.jsx';
import { ActingAsUserProvider } from '@/contexts/ActingAsUserContext.jsx';

export default function Root() {
  return (
    <div className="h-screen w-screen">
      <ActingAsUserProvider>
        <CustomScrollbar>
          <AnimatePresence mode="wait">
            <Outlet />
          </AnimatePresence>
          { process.env.NODE_ENV === 'development' && <TanStackRouterDevtools position="bottom-right" /> }
          <BackToTopButton />
        </CustomScrollbar>
      </ActingAsUserProvider>
    </div>
  );
}
export const Route = createRootRoute({
  component: () => <Root />,
  notFoundComponent: () => <ErrorPage error="404" title="Not Found" content="Die gesuchte Seite wurde nicht gefunden. Überprüfen Sie die URL oder kehren Sie zur Startseite zurück." />,
});
