import {
  useCallback,
  useEffect, useRef, useState,
} from 'react';

const useElementSize = () => {
  const [size, setSize] = useState({
    width: 0,
    height: 0,
  });
  const [element, setElement] = useState(null);

  const elementRef = useCallback((node) => {
    setElement(node);
  }, []);

  const observerRef = useRef(null);

  const updateSize = useCallback(() => {
    if (!element || !element.getBoundingClientRect) return;
    const { width, height } = element.getBoundingClientRect();
    setSize({
      width,
      height,
    });
  }, [element]);

  useEffect(() => {
    updateSize();
  }, [element?.offsetHeight, element?.offsetWidth, updateSize]);

  useEffect(() => {
    if (!element) return () => {};

    observerRef.current = new ResizeObserver(() => {
      updateSize();
    });
    observerRef.current.observe(element);

    return () => {
      observerRef.current.disconnect();
    };
  }, [element, updateSize]);

  return [size, elementRef];
};

export default useElementSize;
